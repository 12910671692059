import { FunctionComponent, useState, useEffect } from 'react';
import {Link, useParams, useNavigate } from 'react-router-dom';
import SquamaNavbar from '../components/squama-navbar';
import {authHeader} from '../helpers/auth-headers';
import { return_logo_from_type } from '../helpers/station-helper';
import { useWebSocket } from '../services/websocket-service';

const SquamaReroute: FunctionComponent = () => {
  const displayName = "SquamaReroute:";
  const enableDebug = true;

  const debug = (...args: any[]) => {
    if (enableDebug) console.debug(displayName, ...args);
  };
  const { socket } = useWebSocket();

  const params = useParams<{mac: string, finalpath: string}>();
  const [canShowLogout , setCanShowLogout] = useState(false);
  const [receivedPleaseWait , setReceivedPleaseWait] = useState(false);
  const [finalPath, setFinalPath] = useState<string>('');
  const history = useNavigate();

  useEffect(() => {
    debug("useEffect");
    const timer = setTimeout( () => setCanShowLogout(true) , 3000);
    debug("params:",params);
    if (!socket) {
      debug("No WS available yet in main useEffect");
      return;
    }
    socket.on("please_wait", (answer: any) => {
      debug("please_wait:", answer);
      setReceivedPleaseWait(true);
    });
    socket.on("answer", (answer: any) => {
      debug("Answer:", answer);
    });
    socket.on("transmitters_desc", (the_transmitters_desc_str: string) => {
      const the_transmitters = JSON.parse(the_transmitters_desc_str);
      debug("Transmitters:", the_transmitters);
      if((the_transmitters)&&(the_transmitters.length>0)){
        debug("Eventualy reroute to : ","/stations/"+the_transmitters[0].station_id.toString()+finalPath)
        history("/stations/"+the_transmitters[0].station_id.toString()+finalPath);
      }
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName, "logged_out:", logged_out_desc_str);
      setCanShowLogout(true);
      setReceivedPleaseWait(false);
    });
    if (params.mac) {
      setCanShowLogout(false);
      const the_request: any = {
        type: "transmitters_desc",
        number: 1,
        liste: [params.mac],
        from: "transmitter_mac",
        token: authHeader()
      };
      debug("ask for transmitters_desc from mac:",params.mac);
      socket.emit("request", the_request);
    }
    if(params.finalpath && finalPath === ''){
      debug("Reroute StationCard to "+params.finalpath);
      setFinalPath("/"+params.finalpath);
    }
    return function cleanup() {
      debug("useEffect.cleanup")
      clearTimeout(timer);
    };
  }, [socket, params, finalPath]);

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-gestion-div-flex-1 the-background">
        <div className="flex-station-cards">
          {(receivedPleaseWait)&&(
              <div className="main-center-column-div">
                <div className="flex-center white-font h1-font">Chargement en cours</div>
                {return_logo_from_type("loader","loader-logo")}
              </div>
          )}
          {(!receivedPleaseWait)&&(canShowLogout)&&(
              <div className="main-center-column-div">
                <div className="flex-center white-font h1-font">Merci pour votre visite</div>
                <Link to='/accueil'><button type="button" className="squama-btn-navbar">
                  Accueil
                </button></Link>
              </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default SquamaReroute;
