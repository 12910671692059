import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { io } from "socket.io-client";

import Station from "../../models/station";
import Company from "../../models/company";

import SiteService, { getSocketIOParams } from "../../services/site-service";
import WebsocketSquamaRequest, {
  WebsocketEmitRequest,
} from "../../models/websocket";
import { authHeader } from "../../helpers/auth-headers";
import {
  createItem,
  getItemFromName,
  Item,
  updateItemFromNameWithValueAndSetter,
} from "../../helpers/items-helper";
import Client from "../../models/client";
import { handleGenericChange } from "../../helpers/handler-helper";

const ModalCreateModifyCompany: FunctionComponent = () => {
  const displayName = "ModalCreateModifyCompany:";
  const enableDebug = true;
  const enableMoreDebug = true;
  const enableDebugWebsocket = true;

  const [stations, setStations] = useState<Station[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [showModal, setshowModal] = useState(false);
  const [websocketEmit, setWebsocketEmit] = useState<
    WebsocketEmitRequest | undefined
  >(undefined);
  const [clients, setClients] = useState<Client[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<number>(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);

  useEffect(() => {
    enableDebugWebsocket && console.log(displayName, "useEffect:", "WEBSOCKET");
    /* BEGINNING websocket connection */
    if (showModal) {
      const socket = io(SiteService.getWebsocketUrl(), getSocketIOParams());
      /* END websocket connection */
      /* BEGINNING listenner setup */
      socket.on("done", (values_desc_str: string) => {
        enableDebugWebsocket &&
          console.log(displayName, "got done:", values_desc_str);
        setshowModal(false);
        setItems([]);
        setSelectedClientId(0);
      });
      socket.on("clients_desc", (the_desc_str: string) => {
        const the_clients = JSON.parse(the_desc_str);
        enableDebug &&
          console.log(displayName, "Clients length:", the_clients.length);
        if (enableMoreDebug) console.log(displayName, "Clients:", the_clients);
        setClients(the_clients);
      });
      socket.on("companies_desc", (the_desc_str: string) => {
        const the_companies = JSON.parse(the_desc_str);
        enableDebug &&
          console.log(displayName, "Companies length:", the_companies.length);
        if (enableMoreDebug)
          console.log(displayName, "Companies:", the_companies);
        setCompanies(the_companies);
      });
      socket.on("stations_desc", (the_desc_str: string) => {
        const the_stations = JSON.parse(the_desc_str);
        enableDebug &&
          console.log(displayName, "stations length:", the_stations.length);
        if (enableMoreDebug)
          console.log(displayName, "stations:", the_stations);
        setStations(the_stations);
      });
      socket.on("logged_out", (logged_out_desc_str: string) => {
        console.log(displayName, "logged_out:", logged_out_desc_str);
        setItems([]);
        setSelectedClientId(0);
      });
      /* END listenner setup */
      /* START websocket emit handler */
      if (websocketEmit) {
        if (enableDebugWebsocket)
          console.log(
            displayName,
            "----------> websocket emit :",
            websocketEmit
          );
        socket.emit(websocketEmit.channel, websocketEmit.request);
      }
      /* END websocket emit handler */
      /* START websocket cleanup handler */
      return function cleanup() {
        enableDebug && console.log(displayName, "useEffect.cleanup");
        socket.disconnect();
      };
      /* END websocket cleanup handler */
    }
  }, [websocketEmit, showModal]);

  useEffect(() => {
    if (!showModal && items.length) {
      setItems([]);
      setSelectedClientId(0);
    }
    if (showModal) {
      enableDebug && console.log(displayName, "Retrieve all existing clients");
      const the_request: any = {
        type: "clients_desc",
        from: "token",
        liste: [],
        token: authHeader(),
      };
      setWebsocketEmit({ channel: "request", request: the_request });
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal && !items.length) {
      enableDebug && console.log(displayName, "Set First Item");
      let newItemArray: Array<Item> = [];
      newItemArray.push(createItem("selected-client", 0));
      newItemArray.push(createItem("name", "Obligatoire"));
      setItems([...items, ...newItemArray]);
    }
  }, [showModal, items]);

  useEffect(() => {
    setCompanies([]);
    setSelectedCompanyId(0);
    if (selectedClientId && clients.length) {
      if (enableDebug) console.log(displayName, "Request all companies_desc");
      const the_request = new WebsocketSquamaRequest(
        "companies_desc",
        authHeader()
      );
      the_request.from = "client_id";
      the_request.number = 1;
      the_request.liste = [selectedClientId];
      setWebsocketEmit({ channel: "request", request: the_request });
    }
  }, [selectedClientId, clients.length]);
  useEffect(() => {
    setStations([]);
    if (selectedCompanyId && companies.length) {
      const theCompany = companies?.find((one) => one.id === selectedCompanyId);
      if (theCompany) {
        updateItemFromNameWithValueAndSetter(
          items,
          "name",
          theCompany.name,
          setItems
        );
      }
      if (enableDebug)
        console.log(
          displayName,
          "Request all stations_desc associated to selectedCompanyId"
        );
      const the_request = new WebsocketSquamaRequest(
        "stations_desc",
        authHeader()
      );
      the_request.from = "company_id";
      the_request.number = 1;
      the_request.liste = [selectedCompanyId];
      the_request.filter = ["simple"];
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    if (!selectedCompanyId) {
      updateItemFromNameWithValueAndSetter(items, "name", "", setItems);
    }
  }, [selectedCompanyId, companies.length]);

  const handleSubmit = () => {
    if (selectedCompanyId) {
      enableDebug && console.log(displayName, "Modify a company");
      const the_request: any = {
        type: "commands",
        liste: [
          JSON.stringify({
            command: "update_company",
            id: selectedClientId,
            name: getItemFromName(items, "name").value,
          }),
        ],
        from: "token",
        token: authHeader(),
      };
      setWebsocketEmit({ channel: "request", request: the_request });
    } else {
      enableDebug && console.log(displayName, "Create a new company");
      const the_request: any = {
        type: "commands",
        liste: [
          JSON.stringify({
            command: "create_company",
            name: getItemFromName(items, "name").value,
            client_id: selectedClientId,
          }),
        ],
        from: "token",
        token: authHeader(),
      };
      setWebsocketEmit({ channel: "request", request: the_request });
    }
  };
  const handleDelete = () => {
    enableDebug && console.log(displayName, "Delete a new company");
    if (window.confirm("Etes vous sur de vouloir supprimer ce groupement ?")) {
      const the_request: any = {
        type: "commands",
        liste: [
          JSON.stringify({
            command: "delete_company",
            id: selectedCompanyId,
          }),
        ],
        from: "token",
        token: authHeader(),
      };
      setWebsocketEmit({ channel: "request", request: the_request });
    }
  };

  function the_modal() {
    return (
      <div className="modal-main">
        <div className="modal-container">
          {/* Start Header */}
          <div className="modal-header">Créer/Modifier un Groupement</div>
          {/* End Header */}
          {/* Start Body */}
          <div className="modal-body m-w-500">
            <div className="flex-nowrap-justify-around">
              <select
                key="selected-client"
                id="selected-client"
                className="squama-select"
                value={selectedClientId}
                onChange={(e) => setSelectedClientId(Number(e.target.value))}
              >
                <option value="0">Sélectionner un client</option>
                {clients?.map((client) => {
                  return (
                    <option value={client.id} key={client.id}>
                      {client.nickname ? client.nickname : client.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {selectedClientId ? (
              <Fragment>
                <hr className="modal-separator" />
                <div className="flex-nowrap-justify-around">
                  <div className="col align-self-center">
                    <select
                      className="squama-select"
                      onChange={(e) =>
                        setSelectedCompanyId(Number(e.target.value))
                      }
                    >
                      <option value="0">Nouveau Groupement</option>
                      {companies &&
                        companies.map((company) => {
                          return (
                            <option value={company.id} key={company.id}>
                              {company.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col align-self-center"></div>
                  {selectedCompanyId ? (
                    <button
                      type="button"
                      className="squama-btn-navbar squama-btn-gestion-delete squama-btn-gestion-modal"
                      onClick={handleDelete}
                    >
                      Supprimer
                    </button>
                  ) : undefined}
                </div>
              </Fragment>
            ) : undefined}
            {selectedClientId ? (
              <Fragment>
                <hr className="modal-separator" />
                <div className="form-floating">
                  <input
                    key="name"
                    type="text"
                    id="name"
                    className="form-control"
                    value={getItemFromName(items, "name").value}
                    onChange={(e) =>
                      handleGenericChange(
                        e,
                        items,
                        setItems,
                        enableDebug,
                        displayName
                      )
                    }
                  />
                  <label className="">Nom du groupement:</label>
                </div>
              </Fragment>
            ) : undefined}
            {selectedCompanyId ? (
              <Fragment>
                <hr className="modal-separator" />
                <div className="flex">
                  <div className="col-4 align-self-center">
                    Stations Associées :
                  </div>
                  <ul className="col">
                    {stations &&
                      stations.map((station) => {
                        return (
                          <li value={station.id} key={"station-" + station.id}>
                            {station.name}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </Fragment>
            ) : undefined}
          </div>
          {/* End Body */}
          {/* Start Footer */}
          <div className="modal-footer">
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              onClick={() => setshowModal(false)}
            >
              Annuler
            </button>
            <button
              type="submit"
              className={
                "squama-btn-navbar squama-btn-gestion-modal squama-btn-gestion"
              }
              onClick={() => handleSubmit()}
            >
              {selectedCompanyId ? "Modifier" : "Ajouter"}
            </button>
          </div>
          {/* End Footer */}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {showModal && the_modal()}
      <button
        type="button"
        className="squama-btn-navbar squama-btn-gestion"
        onClick={() => setshowModal(true)}
      >
        Édition Groupement
      </button>
    </Fragment>
  );
};

export default ModalCreateModifyCompany;
