import { FunctionComponent, useEffect } from "react";
import StationCard from "../components/station-card";
import Station from "../models/station";
import { return_logo_from_type } from "../helpers/station-helper";

/*
 * First parameters we get :
 * id : company_id The company id to display
 * finalpath : Final path to reach in company display
 */

type Props = {
  stations: Array<Station>;
  finalPath?: string;
  stationsNumber: number;
};

const SquamaCompanyDashBoard: FunctionComponent<Props> = ({
  stations,
  finalPath,
  stationsNumber,
}) => {
  const displayName = "SquamaCompanyDashBoard:";
  const enableDebug = false;
  const debug = (...args: any[]) => {
    if (enableDebug) console.debug(displayName, ...args);
  };

  useEffect(() => {
    debug("stations:", stations);
    debug("stations.length:", stations.length);
    debug("finalPath:", finalPath);
    debug("stationsNumber:", stationsNumber);
  }, [debug, finalPath, stations, stationsNumber]);

  return (
    <div className="main-center-div flex-station-cards the-background">
      {stationsNumber || stations?.length
        ? stations &&
          stations.map((station) => (
            <StationCard
              key={station.id}
              station={station}
              fullscreen={false}
              finalpath={finalPath}
              mapMode={false}
            />
          ))
        : undefined}
      {stationsNumber && stationsNumber > stations.length
        ? [...Array(stationsNumber - stations.length)].map((element, index) => (
            <div
              key={"loader-" + index}
              className="panel-station-default panel-station-default-not-fullscreen panel-station-body panel-station-simple-row-justify-center"
            >
              {return_logo_from_type("loader", "icon-sensor-150")}
            </div>
          ))
        : undefined}
    </div>
  );
};
export default SquamaCompanyDashBoard;
