import { FunctionComponent } from "react";

const PageNotFound: FunctionComponent = () => {
  return (
    <div className="main-center-column-div the-background">
      <h1 className="white-font">Cette page n'existe pas !</h1>
      <button
        type="button"
        className="squama-btn-navbar"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = "/accueil";
        }}
      >
        Accueil
      </button>
    </div>
  );
};

export default PageNotFound;
