import { FunctionComponent, useState, useEffect } from 'react';
import {Link, useParams } from 'react-router-dom';
import StationCard from '../components/station-card';
import { io } from "socket.io-client";
import Station from '../models/station';
import {authHeader} from '../helpers/auth-headers';
import SiteService, { getSocketIOParams } from '../services/site-service';
import { return_logo_from_type } from '../helpers/station-helper';

const SquamaSingleStation: FunctionComponent = () => {
  const displayName = "SquamaSingleStation:";
  const enableDebug = false;
  const params = useParams<{id: string, finalpath: string}>();
  const [station, setStation] = useState<Station|null>(null);
  const [canShowLogout , setCanShowLogout] = useState(false);
  const [receivedPleaseWait , setReceivedPleaseWait] = useState(false);
  const [finalPath, setFinalPath] = useState<string>('');

  useEffect(() => {
    const timer = setTimeout( () => setCanShowLogout(true) , 3000);
    (params)&&(enableDebug)&&console.log(displayName, "params:",params);
    const socket = io(SiteService.getWebsocketUrl(), getSocketIOParams());
    socket.on("please_wait", (answer: any) => {
      enableDebug&&console.log(displayName, "please_wait:", answer);
      setReceivedPleaseWait(true);
    });
    socket.on("answer", (answer: any) => {
      enableDebug&&console.log(displayName, "Answer:", answer);
    });
    socket.on("stations_desc", (the_stations_desc_str: string) => {
      const the_stations = JSON.parse(the_stations_desc_str);
      if (enableDebug && the_stations) console.log(displayName, "Stations:", the_stations);
      (the_stations)&&(the_stations.length>0)&&setStation(the_stations[0]);
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName, "logged_out:", logged_out_desc_str);
      setCanShowLogout(true);
      setReceivedPleaseWait(false);
      setStation(null);
    });
    if (params.id) {
      setStation(null);
      setCanShowLogout(false);
      const the_request: any = {
        type: "stations_desc",
        number: 1,
        liste: [+params.id],
        from: "station_id",
        token: authHeader()
      };
      (enableDebug)&&console.log(displayName, "ask for station_desc:",+params.id);
      socket.emit("request", the_request);
    }
    if(params.finalpath){
      (enableDebug)&&console.log(displayName, "Reroute StationCard to "+params.finalpath);
      setFinalPath(params.finalpath);
    }
    return function cleanup() {
      enableDebug && console.log(displayName, "useEffect.cleanup")
      socket.disconnect();
      clearTimeout(timer);
    };
  }, [params]);

  return (
    <div className="in-page-flex">
      <div className="row-navbar">
        {(station) && (
          <div key={"div-company"} className="squama-item-navbar squama-item-navbar-companies">
            <Link to={'/companies/' + station.company_id}>
              <button type="button" key={"button-" + station.company_id} className={"squama-btn-navbar-companies btn-selected"}>
                {station.company_name}
              </button>
            </Link>
          </div>
        )}
      </div>
      <hr/>
      <div className="main-gestion-div-flex-1 the-background">
        <div className="flex-station-cards">
          {(station)&&(<StationCard key={station.id} station={station} fullscreen={true} finalpath={finalPath} mapMode={false}/>)}
          {(!station)&&(receivedPleaseWait)&&(
              <div className="main-center-column-div">
                <div className="flex-center white-font h1-font">Chargement en cours</div>
                {return_logo_from_type("loader","loader-logo")}
              </div>
          )}
          {(!station)&&(!receivedPleaseWait)&&(canShowLogout)&&(
              <div className="main-center-column-div">
                <div className="flex-center white-font h1-font">Merci pour votre visite</div>
                <Link to='/accueil'><button type="button" className="squama-btn-navbar">
                  Accueil
                </button></Link>
              </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default SquamaSingleStation;
