import Transmitter from "../models/transmitter";
import AnalogSensor from "../models/analog-sensor";
import { convertIntoType, updateOtherDateWithStartEndDateAndModif } from "./tools";
import WaterLevelSensor from "../models/water-level-sensor";
import Contact from "../models/contact";
import Station from "../models/station";
import FaultTrigger from "../models/fault-trigger";
import { station_get_pkd_fault_trigger } from "./station-helper";
import Fault from "../models/fault";

export type Item = {
  name: string;
  value?: any;
  initial_value?: any;
  error?: string;
  isValid?: boolean;
  isDisabled?: boolean;
  type?: string;
};

export function moveUpInArray(theArray: Array<any>, index: number): Array<any> {
  if (index === 0) return theArray; // Ne rien faire si c'est le premier élément
  const newArrayOrdered = [...theArray];
  [newArrayOrdered[index - 1], newArrayOrdered[index]] = [
    newArrayOrdered[index],
    newArrayOrdered[index - 1],
  ];
  return newArrayOrdered;
}

export function moveDownInArray(
  theArray: Array<any>,
  index: number
): Array<any> {
  if (index === theArray.length - 1) return theArray; // Ne rien faire si c'est le dernier élément
  const newArrayOrdered = [...theArray];
  [newArrayOrdered[index], newArrayOrdered[index + 1]] = [
    newArrayOrdered[index + 1],
    newArrayOrdered[index],
  ];
  return newArrayOrdered;
}

export function findItemFromName(items: Array<Item>, theName: string) {
  return items.find((one) => one.name === theName);
}

export function updateItemFromNameWithValueAndSetter(
  items: Array<Item>,
  theName: string,
  theValue: any,
  theSetter: any | undefined = undefined,
  theValueType: string = ""
): Array<Item> {
  //check if theValueType is fine else return without modifying
  if (theValueType !== "" && theValue !== "") {
    const convertedValue = convertIntoType(theValue, theValueType);
    if (convertedValue.toString() !== theValue.toString()) {
      return items;
    }
  }
  const theItems = [...items];
  let theItem = findItemFromName(items, theName);
  if (theItem) {
    theItem.value = convertIntoType(theValue, theValueType);
    theSetter && theSetter(theItems);
    return theItems;
  } else {
    items.push(createItem(theName, convertIntoType(theValue, theValueType)));
    theSetter && theSetter(items);
    return items;
  }
}

export function createItem(
  theName: string,
  initialValue: any,
  type: any | undefined = undefined
): Item {
  return {
    name: theName,
    value: initialValue,
    initial_value: initialValue,
    isValid: true,
    isDisabled: false,
    type: type ? type : undefined,
  };
}

/**
 * Ajoute un item au tableau.
 * @param {Item[]} items - Tableau existant d'items.
 * @param {Item} newItem - Item à ajouter.
 * @returns {Item[]} - Nouveau tableau d'items.
 */
function addItem(items: Array<Item>, newItem: Item) {
  // Vérifier si l'item existe déjà
  const exists = items.some((item) => item.name === newItem.name);
  if (exists) {
    throw new Error(`Un item avec le nom "${newItem.name}" existe déjà.`);
  }

  return [...items, newItem];
}

/**
 * Supprime un item du tableau par son nom.
 * @param {Item[]} items - Tableau existant d'items.
 * @param {string} name - Nom de l'item à supprimer.
 * @returns {Item[]} - Nouveau tableau d'items.
 */
function removeItemFromName(items: Array<Item>, name: string) {
  const filteredItems = items.filter((item) => item.name !== name);
  //if (filteredItems.length === items.length) {
  //  throw new Error(`Aucun item trouvé avec le nom "${name}".`);
  //}
  return filteredItems;
}

//export function createItem(theName: string, initialValue: any): Item {
//  return createItem(theName, initialValue, undefined);
//}

export function displayItems(displayName: string, items: Array<Item>) {
  console.log(displayName, "########");
  console.log(displayName, "Into displayInputFields");
  items.forEach((item) => {
    console.log(
      displayName,
      "item=> name:" + item.name + ";value:" + item.value
    );
  });
  //console.log(displayName,getItemFromName("station_name").value)
  console.log(displayName, "########");
}

export function getItemsNameContainingTheStrings(
  items: Array<Item>,
  theStrings: Array<string>
): Array<string> {
  const enableDebugDisplayNullValue = false;
  const enableDebugDisplayUndefinedValue = false;
  const default_returned: Array<string> = [];
  if (items !== undefined) {
    //let returned = items.find((one) => one.name === name);
    items.forEach((one) => {
      if (!theStrings.find((one_string) => !one.name.includes(one_string))) {
        default_returned.push(one.name);
      }
    });
  }
  return default_returned;
}

export function getItemFromName(items: Array<Item>, name: string): Item {
  const enableDebugDisplayNullValue = false;
  const enableDebugDisplayUndefinedValue = false;
  const default_returned: Item = {
    name: "",
    value: "",
    error: "unknown item:" + name,
  };
  if (items !== undefined) {
    let returned = items.find((one) => one.name === name);
    if (returned !== undefined) {
      if (returned.value === undefined || returned.value === null) {
        returned.value === null &&
          enableDebugDisplayNullValue &&
          console.log("Item named:", returned.name, " is null");
        returned.value === undefined &&
          enableDebugDisplayUndefinedValue &&
          console.log("Item named:", returned.name, " is undefined");
        returned.value = "";
      }
      return returned;
    }
  }
  return default_returned;
}

export function toggleItemFromName(
  items: Array<Item>,
  name: string,
  theSetter: any | undefined
): Array<Item> {
  const the_value = getItemFromName(items, name);
  return updateItemFromNameWithValueAndSetter(
    items,
    name,
    !the_value?.value,
    theSetter
  );
}
export function setStationItems(
  newItemArray: Array<Item>,
  station: Station
): void {
  newItemArray.push(createItem("station-name", station.name, null));
  newItemArray.push(
    createItem(
      "station-migration_date_start",
      station.migration_date_start
        ? station.migration_date_start
        : "2021-01-01",
      null
    )
  );
  newItemArray.push(
    createItem(
      "station-migration_date_end",
      station.migration_date_end ? station.migration_date_end : "2021-12-31",
      null
    )
  );
  newItemArray.push(
    createItem(
      "station-in_migration_forced",
      station.in_migration_forced ? 1 : 0,
      null
    )
  );
  const pkd_fault_trigger = station_get_pkd_fault_trigger(station);
  if (pkd_fault_trigger !== undefined) {
    newItemArray.push(
      createItem("station-alarm_pkd_days", pkd_fault_trigger.alarm_days, null)
    );
    newItemArray.push(
      createItem(
        "station-alarm_pkd_temperature",
        pkd_fault_trigger.alarm_level_high,
        null
      )
    );
  } else {
    newItemArray.push(createItem("station-alarm_pkd_days", 15, null));
    newItemArray.push(createItem("station-alarm_pkd_temperature", 15, null));
  }
}

export function removeOneFaultTrigger(
  items: Array<Item>,
  theSetter: any,
  itsId: number | string,
  itsType: string
) {
  //const searchedName = "fault_trigger-"+itsId+"-"+itsType+"-alarm_level_low"
  //const theItemToRemove = findItemFromName(items,searchedName);
  //if(theItemToRemove){
  //  const theItemPos = items.indexOf()
  //  //items.pop();
  //  //delete items[theItemToRemove];
  //}
  let updatedItems = removeItemFromName(
    items,
    "fault_trigger-" + itsId + "-" + itsType + "-alarm_level_low"
  );
  updatedItems = removeItemFromName(
    updatedItems,
    "fault_trigger-" + itsId + "-" + itsType + "-alarm_level_high"
  );
  updatedItems = removeItemFromName(
    updatedItems,
    "fault_trigger-" + itsId + "-" + itsType + "-warning_level_low"
  );
  updatedItems = removeItemFromName(
    updatedItems,
    "fault_trigger-" + itsId + "-" + itsType + "-warning_level_high"
  );
  updatedItems = removeItemFromName(
    updatedItems,
    "fault_trigger-" + itsId + "-" + itsType + "-trigger_high"
  );
  updatedItems = removeItemFromName(
    updatedItems,
    "fault_trigger-" + itsId + "-" + itsType + "-alarm_days"
  );
  updatedItems = removeItemFromName(
    updatedItems,
    "fault_trigger-" + itsId + "-" + itsType + "-warning_days"
  );
  updatedItems = removeItemFromName(
    updatedItems,
    "fault_trigger-" + itsId + "-" + itsType + "-name"
  );
  theSetter(updatedItems);
}

export function setOneFaultTrigger(
  newItemArray: Array<Item>,
  oneFaultTrigger: FaultTrigger,
  forcedId: number | string,
  fault: Fault | undefined
) {
  //explanation in sqlalchemy_api.py : Station() > add_element_value
  //not using warning_level_low
  //not using warning_level_high
  newItemArray.push(
    createItem(
      "fault_trigger-" +
        forcedId +
        "-" +
        oneFaultTrigger.type +
        "-alarm_level_low",
      oneFaultTrigger.alarm_level_low,
      null
    )
  );
  newItemArray.push(
    createItem(
      "fault_trigger-" +
        forcedId +
        "-" +
        oneFaultTrigger.type +
        "-alarm_level_high",
      oneFaultTrigger.alarm_level_high,
      null
    )
  );
  /* We need to know if we consider high or low for triggering */
  newItemArray.push(
    createItem(
      "fault_trigger-" +
        forcedId +
        "-" +
        oneFaultTrigger.type +
        "-trigger_high",
      oneFaultTrigger.alarm_level_high === -1 ? 0 : 1,
      null
    )
  );
  newItemArray.push(
    createItem(
      "fault_trigger-" + forcedId + "-" + oneFaultTrigger.type + "-alarm_days",
      oneFaultTrigger.alarm_days,
      null
    )
  );
  newItemArray.push(
    createItem(
      "fault_trigger-" +
        forcedId +
        "-" +
        oneFaultTrigger.type +
        "-warning_days",
      oneFaultTrigger.warning_days,
      null
    )
  );
  newItemArray.push(
    createItem(
      "fault_trigger-" + forcedId + "-" + oneFaultTrigger.type + "-name",
      oneFaultTrigger.name,
      null
    )
  );
  if (fault) {
    if (
      fault.trigger_type.toString() === "0" ||
      fault.trigger_type.toString() === "1"
    ) {
      newItemArray.push(
        createItem(
          "fault_trigger-" +
            forcedId +
            "-" +
            oneFaultTrigger.type +
            "-fault_status",
          "orange",
          null
        )
      );
    }
    if (
      fault.trigger_type.toString() === "2" ||
      fault.trigger_type.toString() === "3"
    ) {
      newItemArray.push(
        createItem(
          "fault_trigger-" +
            forcedId +
            "-" +
            oneFaultTrigger.type +
            "-fault_status",
          "red",
          null
        )
      );
    }
  }
}

export function setFaultTriggersButPKD(
  newItemArray: Array<Item>,
  faultTriggers: Array<FaultTrigger> | undefined,
  faults: Array<Fault> | undefined
) {
  faultTriggers?.forEach((one) => {
    if (one.type !== "pkd") {
      const associated_fault = faults?.find(
        (one_fault) => one_fault.fault_trigger_id === one.id
      );
      setOneFaultTrigger(newItemArray, one, one.id, associated_fault);
    }
  });
}

export function setTransmitterItems(
  newItemArray: Array<Item>,
  transmitters: Array<Transmitter> | undefined
): void {
  if (transmitters === undefined) {
    return;
  }
  transmitters?.forEach((transmitter) => {
    if (transmitter.new_sample_each_n_quarter) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-samples",
          transmitter.new_sample_each_n_quarter
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-samples",
          transmitter.current_sample_each_n_quarter
        )
      );
    }
    if (transmitter.new_send_each_n_quarter) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-frequency",
          transmitter.new_send_each_n_quarter
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-frequency",
          transmitter.current_send_each_n_quarter
        )
      );
    }
    if (transmitter.new_temp_mask !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-temperaturemask",
          transmitter.new_temp_mask
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-temperaturemask",
          transmitter.current_temp_mask
        )
      );
    }
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-askota",
        transmitter.firmware_ota_uc_path
      )
    );
    newItemArray.push(
      createItem("transmitter-" + transmitter.id + "-type", transmitter.type)
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-askgps",
        transmitter.askgps
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-askversion",
        transmitter.askversion
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-askuuid",
        transmitter.askuuid
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-askvariables",
        transmitter.askvariables
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-askpoweroff",
        transmitter.askpoweroff
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-askforce_attach",
        transmitter.askforce_attach
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-askupgrade_capteurs",
        transmitter.askupgrade_capteurs
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-asksleep_n_min",
        transmitter.asksleep_n_min
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-askutc_wake_up_hour",
        transmitter.askutc_wake_up_hour
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-askutc_wake_up_minute",
        transmitter.askutc_wake_up_minute
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-resendparameters",
        transmitter.resend_parameters
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-freestyle",
        transmitter.freestyle ? transmitter.freestyle : ""
      )
    );
    if (transmitter.new_modem_type !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-modemtype",
          transmitter.new_modem_type
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-modemtype",
          transmitter.current_modem_type
        )
      );
    }
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-comments",
        transmitter.comments
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-gps_lon",
        transmitter.gps_lon
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-gps_lat",
        transmitter.gps_lat
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-installation_date",
        transmitter.installation_date
      )
    );
    if (transmitter.new_always_attach !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-always_attach",
          transmitter.new_always_attach
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-always_attach",
          transmitter.current_always_attach
        )
      );
    }
    if (transmitter.new_vin_mv !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-vin_mv",
          transmitter.new_vin_mv
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-vin_mv",
          transmitter.current_vin_mv
        )
      );
    }
    if (transmitter.new_gprs_big_data !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-gprs_big_data",
          transmitter.new_gprs_big_data
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-gprs_big_data",
          transmitter.current_gprs_big_data
        )
      );
    }
    if (transmitter.new_force_gsm !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-force_gsm",
          transmitter.new_force_gsm
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-force_gsm",
          transmitter.current_force_gsm
        )
      );
    }
    //
    if (transmitter.new_allow_nbiot !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-allow_nbiot",
          transmitter.new_allow_nbiot
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-allow_nbiot",
          transmitter.current_allow_nbiot
        )
      );
    }
    if (transmitter.new_keep_sample !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-keep_sample",
          transmitter.new_keep_sample
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-keep_sample",
          transmitter.current_keep_sample
        )
      );
    }
    if (transmitter.new_my_operator) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-my_operator",
          transmitter.new_my_operator
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-my_operator",
          transmitter.current_my_operator
        )
      );
    }
    if (transmitter.new_wifi_ssid_1) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-wifi_ssid_1",
          transmitter.new_wifi_ssid_1
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-wifi_ssid_1",
          transmitter.current_wifi_ssid_1
        )
      );
    }
    if (transmitter.new_wifi_pass_1) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-wifi_pass_1",
          transmitter.new_wifi_pass_1
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-wifi_pass_1",
          transmitter.current_wifi_pass_1
        )
      );
    }
    if (transmitter.new_wifi_ssid_2) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-wifi_ssid_2",
          transmitter.new_wifi_ssid_2
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-wifi_ssid_2",
          transmitter.current_wifi_ssid_2
        )
      );
    }
    if (transmitter.new_wifi_pass_2) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-wifi_pass_2",
          transmitter.new_wifi_pass_2
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-wifi_pass_2",
          transmitter.current_wifi_pass_2
        )
      );
    }
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-connector",
        transmitter.connector
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-serial",
        transmitter.serial
      )
    );
    if (transmitter.new_rs_squama_on !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-rs_squama_on",
          transmitter.new_rs_squama_on
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-rs_squama_on",
          transmitter.current_rs_squama_on
        )
      );
    }
    if (transmitter.new_rs_modbus_on !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-rs_modbus_on",
          transmitter.new_rs_modbus_on
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-rs_modbus_on",
          transmitter.current_rs_modbus_on
        )
      );
    }
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-installation_squama",
        transmitter.installation_squama
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-get_cells",
        transmitter.get_cells
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-get_all_operators",
        transmitter.get_all_operators
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-get_operators",
        transmitter.get_operators
      )
    );
    newItemArray.push(
      createItem(
        "transmitter-" + transmitter.id + "-get_wifi_around",
        transmitter.get_wifi_around
      )
    );
    if (transmitter.new_allow_2g_ota !== null) {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-allow_2g_ota",
          transmitter.new_allow_2g_ota
        )
      );
    } else {
      newItemArray.push(
        createItem(
          "transmitter-" + transmitter.id + "-allow_2g_ota",
          transmitter.current_allow_2g_ota
        )
      );
    }
  });
}

export function setContactAlarmItems(
  newItemArray: Array<Item>,
  contact: Contact
): void {
  newItemArray.push(
    createItem("contact-alarm-" + contact.user_id, contact.alarm, null)
  );
  newItemArray.push(
    createItem("contact-warning-" + contact.user_id, contact.warning, null)
  );
  newItemArray.push(
    createItem(
      "contact-alimentation_alarm-" + contact.user_id,
      contact.alimentation_alarm,
      null
    )
  );
  newItemArray.push(
    createItem(
      "contact-alimentation_warning-" + contact.user_id,
      contact.alimentation_warning,
      null
    )
  );
  newItemArray.push(
    createItem(
      "contact-embacle_alarm-" + contact.user_id,
      contact.embacle_alarm,
      null
    )
  );
  newItemArray.push(
    createItem(
      "contact-embacle_warning-" + contact.user_id,
      contact.embacle_warning,
      null
    )
  );
  newItemArray.push(
    createItem("contact-alarm_pkd-" + contact.user_id, contact.alarm_pkd, null)
  );
}

export function setAnalogSensorAlarmItems(
  newItemArray: Array<Item>,
  sensor: AnalogSensor
): void {
  newItemArray.push(
    createItem(
      "analog-alarm_level_low-" + sensor.id,
      sensor.alarm_level_low,
      sensor.type
    )
  );
  newItemArray.push(
    createItem(
      "analog-alarm_level_high-" + sensor.id,
      sensor.alarm_level_high,
      sensor.type
    )
  );
  newItemArray.push(
    createItem(
      "analog-warning_level_low-" + sensor.id,
      sensor.warning_level_low,
      sensor.type
    )
  );
  newItemArray.push(
    createItem(
      "analog-warning_level_high-" + sensor.id,
      sensor.warning_level_high,
      sensor.type
    )
  );
}

export function setWaterLevelSensorAlarmItems(
  newItemArray: Array<Item>,
  wl_sensor: WaterLevelSensor,
  station_is_pap: boolean
): void {
  if (
    station_is_pap &&
    wl_sensor.type === "downstream" &&
    wl_sensor.depth === 0
  ) {
    newItemArray.push(
      createItem("water_level-alarm_level-" + wl_sensor.id, -1, wl_sensor.type)
    );
    newItemArray.push(
      createItem(
        "water_level-warning_level-" + wl_sensor.id,
        -1,
        wl_sensor.type
      )
    );
    newItemArray.push(
      createItem(
        "water_level-trigger_under_water-" + wl_sensor.id,
        wl_sensor.trigger_under_water,
        wl_sensor.type
      )
    );
  } else {
    newItemArray.push(
      createItem(
        "water_level-alarm_level-" + wl_sensor.id,
        wl_sensor.alarm_level,
        wl_sensor.type
      )
    );
    newItemArray.push(
      createItem(
        "water_level-warning_level-" + wl_sensor.id,
        wl_sensor.warning_level,
        wl_sensor.type
      )
    );
    newItemArray.push(
      createItem(
        "water_level-trigger_under_water-" + wl_sensor.id,
        wl_sensor.trigger_under_water,
        wl_sensor.type
      )
    );
  }
}

export function checkStartEndDatesConsistencyInItems(
  items: Array<Item>,
  startDateItemName:string,
  endDateItemName:string,
  modifItemName: string,
  enableLocalDebug:boolean = false
): Array<Item> {
  let startDateValue = getItemFromName(
    items,
    startDateItemName
  ).value.toString();
  let endDateValue = getItemFromName(
    items,
    endDateItemName
  ).value.toString();
  enableLocalDebug&&console.log("IN startDateValue:",startDateValue);
  enableLocalDebug&&console.log("IN endDateValue:",endDateValue);
  enableLocalDebug&&console.log("IN modifItemName:",modifItemName);
  if (modifItemName === startDateItemName) {
    endDateValue = updateOtherDateWithStartEndDateAndModif(
      startDateValue,
      endDateValue,
      "start"
    );
  }
  if (modifItemName===endDateItemName) {
    startDateValue = updateOtherDateWithStartEndDateAndModif(
      startDateValue,
      endDateValue,
      "end"
    );
  }
  let newItems = updateItemFromNameWithValueAndSetter(
    items,
    startDateItemName,
    startDateValue
  );
  newItems = updateItemFromNameWithValueAndSetter(
    newItems,
    endDateItemName,
    endDateValue
  );
  enableLocalDebug&&console.log("OUT startDateValue:",startDateValue);
  enableLocalDebug&&console.log("OUT endDateValue:",endDateValue);
  return newItems;
}
