import {
  FormEvent,
  ChangeEvent,
  FunctionComponent,
  useState,
  useEffect,
  Fragment,
} from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import FaultTrigger from "../models/fault-trigger";
import Station from "../models/station";
import Contact from "../models/contact";
import WaterLevelSensor from "../models/water-level-sensor";

import StationService from "../services/station-service";
import AuthenticationService from "../services/authentication-service";

import {
  find_water_level_sensor_from_sensor_id,
  find_analog_sensor_from_sensor_id,
  is_station_pap,
  is_there_sensor_in_array,
  return_logo_from_type,
  return_color_for_water_level_from_bool,
  return_unity_from_type,
  return_type_test,
  is_station_papang,
  is_station_fully_loaded,
  is_station_can_enable_pkd,
  station_get_pkd_fault_trigger,
  get_all_typed_water_level_sensors_from_station,
  get_all_typed_analog_sensors_from_station,
} from "../helpers/station-helper";
import {
  getItemFromName,
  Item,
  setAnalogSensorAlarmItems,
  setContactAlarmItems,
  setStationItems,
  setFaultTriggersButPKD,
  setWaterLevelSensorAlarmItems,
  toggleItemFromName,
  getItemsNameContainingTheStrings,
  setOneFaultTrigger,
  removeOneFaultTrigger,
  updateItemFromNameWithValueAndSetter,
  checkStartEndDatesConsistencyInItems,
} from "../helpers/items-helper";
import {
  handleGenericChange,
  handleGenericCheckboxChange,
} from "../helpers/handler-helper";
import { uiNumericInput } from "../helpers/ui-helper";
import AnalogSensor from "../models/analog-sensor";

type Props = {
  station: Station;
};

const StationCardAlarm: FunctionComponent<Props> = ({ station }) => {
  const displayName = "StationCardAlarm:";
  const enableDebug = false;
  const enableMoreDebug = false;

  const debug = (...args: any[]) => {
    if (enableDebug) console.debug(displayName, ...args);
  };
  const debugMore = (...args: any[]) => {
    if (enableMoreDebug) console.debug(displayName, ...args);
  };

  debugMore("station:", station);
  const stationFullyLoaded = is_station_fully_loaded(station);
  const pkd_enabled = is_station_can_enable_pkd(station);
  const current_user_is_god = AuthenticationService.getUserIsGod();
  const current_user_can_modify = AuthenticationService.getUserCanAccessModify(
    station,
    "alarm"
  );
  const current_user_has_technical_access =
    AuthenticationService.getUserHaveTechnicalAccess(station);
  const current_user_is_restricted =
    AuthenticationService.getUserIsRestrictedToStation(station);
  const history = useNavigate();

  const [help, setHelp] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [items, setItems] = useState<Item[]>([]);
  const [isInMigration, setIsInMigration] = useState<boolean>(false);
  const [allowWaterLevelWarning, setAllowWaterLevelWarning] =
    useState<boolean>(false);
  const [allowWaterLevelAlarm, setAllowWaterLevelAlarm] =
    useState<boolean>(false);
  const [allowWaterLevelEmbacleWarning, setAllowWaterLevelEmbacleWarning] =
    useState<boolean>(false);
  const [allowWaterLevelEmbacleAlarm, setAllowWaterLevelEmbacleAlarm] =
    useState<boolean>(false);
  const [enablePKD, setEnablePKD] = useState<boolean>(false);
  const [
    newLiquidLevelFaultTriggerIdArray,
    setNewLiquidLevelFaultTriggerIdArray,
  ] = useState<string[]>([]);

  let general_water_level_sensors =
    get_all_typed_water_level_sensors_from_station(station, "general");
  let upstream_water_level_sensors =
    get_all_typed_water_level_sensors_from_station(station, "upstream");
  let downstream_water_level_sensors =
    get_all_typed_water_level_sensors_from_station(station, "downstream");

  function updateStartEndMigrationDate(
    items: Array<Item>,
    theModifiedItem: Item,
    modif: string
  ): Array<Item> {
    return checkStartEndDatesConsistencyInItems(
      items,
      "station-migration_date_start",
      "station-migration_date_end",
      modif
    );
  }

  const handleMigrationDateOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    debug(
      "handleMigrationDateOnChange",
      "id:" + e.target.id,
      "value:" + e.target.value
    );
    const theItems = [...items];
    theItems.find((one) => {
      if (one.name === e.target.id) {
        one.value = e.target.value;
        setItems(updateStartEndMigrationDate(theItems, one, e.target.id));
      }
      return null;
    });
  };

  function checkInMigrationPeriod() {
    let start_date = getItemFromName(
      items,
      "station-migration_date_start"
    ).value;
    let end_date = getItemFromName(items, "station-migration_date_end").value;
    if (start_date != null && end_date != null) {
      let now = new Date();
      let p_start_date = new Date(start_date);
      let p_end_date = new Date(end_date);
      if (p_start_date <= now && now <= p_end_date) {
        debug("Currently in migration");
        return true;
      }
    }
    debug("Currently NOT in migration");
    return false;
  }

  function updateIsInMigration() {
    if (is_station_pap(station)) {
      if (getItemFromName(items, "station-in_migration_forced").value) {
        setIsInMigration(true);
      } else {
        if (checkInMigrationPeriod()) {
          setIsInMigration(true);
        } else {
          setIsInMigration(false);
        }
      }
    } else {
      setIsInMigration(false);
    }
  }

  useEffect(() => {
    debug("useEffect:##SETUP BEGIN");
    let newItemArray: Array<Item> = [];

    const pkd_fault_trigger = station_get_pkd_fault_trigger(station);
    if (pkd_enabled && pkd_fault_trigger) {
      setEnablePKD(true);
    }
    setStationItems(newItemArray, station);
    setFaultTriggersButPKD(newItemArray, station.faulttriggers, station.faults);
    station.contacts?.forEach((contact) => {
      setContactAlarmItems(newItemArray, contact);
    });
    station.transmitters?.forEach((transmitter) =>
      transmitter?.analog_sensors?.forEach((sensor) =>
        setAnalogSensorAlarmItems(newItemArray, sensor)
      )
    );
    station.transmitters?.forEach((transmitter) =>
      transmitter?.water_level_sensors?.forEach((wl_sensor) =>
        setWaterLevelSensorAlarmItems(
          newItemArray,
          wl_sensor,
          is_station_pap(station)
        )
      )
    );
    setNewLiquidLevelFaultTriggerIdArray([]);
    setItems(newItemArray);
    debug("useEffect:##SETUP END");
  }, [station]);

  function removeContactAlarmIfDisable(
    theItems: Array<Item>,
    param_allowWaterLevelAlarm: boolean,
    param_allowWaterLevelWarning: boolean,
    param_allowWaterLevelEmbacleAlarm: boolean,
    param_allowWaterLevelEmbacleWarning: boolean
  ) {
    if (theItems.length) {
      if (!param_allowWaterLevelAlarm) {
        theItems.forEach((oneItem) => {
          if (oneItem.name.includes("contact-alimentation_alarm")) {
            if (oneItem.value) {
              debugMore(" disable alimentation_alarm for contact:", oneItem);
              oneItem.value = 0;
            }
          }
        });
      }
      if (!param_allowWaterLevelWarning) {
        theItems.forEach((oneItem) => {
          if (oneItem.name.includes("contact-alimentation_warning")) {
            if (oneItem.value) {
              debugMore(" disable alimentation_warning for contact:", oneItem);
              oneItem.value = 0;
            }
          }
        });
      }
      if (!param_allowWaterLevelEmbacleAlarm) {
        theItems.forEach((oneItem) => {
          if (oneItem.name.includes("contact-embacle_alarm")) {
            if (oneItem.value) {
              debugMore(" disable embacle_alarm for contact:", oneItem);
              oneItem.value = 0;
            }
          }
        });
      }
      if (!param_allowWaterLevelEmbacleWarning) {
        theItems.forEach((oneItem) => {
          if (oneItem.name.includes("contact-embacle_warning")) {
            if (oneItem.value) {
              debugMore(" disable embacle_warning for contact:", oneItem);
              oneItem.value = 0;
            }
          }
        });
      }
    }
  }

  function checkWaterLevelAlarmWarningContactConsistency(
    theItems: Array<Item>
  ) {
    if (theItems.length) {
      let param_allowWaterLevelWarning = false;
      let param_allowWaterLevelAlarm = false;
      let param_allowWaterLevelEmbacleWarning = false;
      let param_allowWaterLevelEmbacleAlarm = false;
      setAllowWaterLevelWarning(false);
      setAllowWaterLevelAlarm(false);
      setAllowWaterLevelEmbacleWarning(false);
      setAllowWaterLevelEmbacleAlarm(false);
      theItems.forEach((oneItem) => {
        if (oneItem.name.includes("water_level")) {
          if (oneItem.type && oneItem.type.includes("downstream")) {
            if (oneItem.name.includes("warning") && oneItem.value >= 0) {
              //console.log(displayName, "Embacle WARN ENABLED=>"+oneItem.name+":"+oneItem.value)
              setAllowWaterLevelEmbacleWarning(true);
              param_allowWaterLevelEmbacleWarning = true;
            }
            if (oneItem.name.includes("alarm") && oneItem.value >= 0) {
              //console.log(displayName, "Embacle ALARM ENABLED=>"+oneItem.name+":"+oneItem.value)
              setAllowWaterLevelEmbacleAlarm(true);
              param_allowWaterLevelEmbacleAlarm = true;
            }
          } else {
            if (oneItem.name.includes("warning") && oneItem.value >= 0) {
              //console.log(displayName, "WARN ENABLED=>"+oneItem.name+":"+oneItem.value)
              setAllowWaterLevelWarning(true);
              param_allowWaterLevelWarning = true;
            }
            if (oneItem.name.includes("alarm") && oneItem.value >= 0) {
              //console.log(displayName, "ALARM ENABLED=>"+oneItem.name+":"+oneItem.value)
              setAllowWaterLevelAlarm(true);
              param_allowWaterLevelAlarm = true;
            }
          }
        } else if (oneItem.name.includes("liquid_level")) {
          if (oneItem.name.includes("warning_days") && oneItem.value >= 0) {
            setAllowWaterLevelWarning(true);
            param_allowWaterLevelWarning = true;
          }
          if (oneItem.name.includes("alarm_days") && oneItem.value >= 0) {
            setAllowWaterLevelAlarm(true);
            param_allowWaterLevelAlarm = true;
          }
        }
      });
      removeContactAlarmIfDisable(
        items,
        param_allowWaterLevelAlarm,
        param_allowWaterLevelWarning,
        param_allowWaterLevelEmbacleAlarm,
        param_allowWaterLevelEmbacleWarning
      );
    }
  }

  function getLiquidLevelFaultTriggerIdsInItems(): string[] {
    return getItemsNameContainingTheStrings(items, [
      "liquid_level",
      "fault_trigger",
      "name",
    ]).map((one) => {
      return one.split("-")[1];
    });
  }

  useEffect(() => {
    debug("items:", items);
    if (items.length) {
      checkWaterLevelAlarmWarningContactConsistency(items);
      updateIsInMigration();
      getLiquidLevelFaultTriggerIdsInItems().forEach((one) => {
        checkLiquidLevelConsistency(one);
      });
    }
  }, [items]);

  const updateStation = () => {
    StationService.updateStation(station).then(() => {
      history(window.location.pathname);
      window.location.reload();
    });
  };

  const handleOnChangeWaterLevelSensor = (
    input: HTMLInputElement,
    value: number | null
  ) => {
    const theItems = [...items];
    theItems.find((one) => {
      if (one.name === input.id) {
        one.value = value;
        updateMaxWithMinValueWaterLevelSensor(one, theItems, input.id);
      }
      return null;
    });
    setItems(theItems);
  };

  const handleOnChangeAnalogSensor = (
    input: HTMLInputElement,
    value: number | null
  ) => {
    if (value !== null) {
      const theItems = [...items];
      theItems.forEach((one) => {
        if (one.name === input.id) {
          one.value = value;
          if (one.value === "") {
            one.value = 0;
          }
          updateMaxWithMinValueAnalogSensor(one, theItems, input.id);
        }
      });
      setItems(theItems);
    }
  };

  function ModifiedMustBeHigherOrEqual(
    theModifiedItem: Item | undefined,
    theOtherItem: Item | undefined
  ): void {
    if (theModifiedItem !== undefined && theOtherItem !== undefined) {
      if (theModifiedItem.value < theOtherItem.value) {
        theOtherItem.value = theModifiedItem.value;
      }
    }
  }

  function ModifiedMustBeLowerOrEqual(
    theModifiedItem: Item | undefined,
    theOtherItem: Item | undefined
  ): void {
    if (theModifiedItem !== undefined && theOtherItem !== undefined) {
      if (theModifiedItem.value > theOtherItem.value) {
        theOtherItem.value = theModifiedItem.value;
      }
    }
  }

  function updateMaxWithMinValueAnalogSensor(
    theModifiedItem: Item,
    theItems: Array<Item>,
    modif: string
  ): void {
    if (modif.includes("analog-alarm_level_low-")) {
      let splited_name = modif.split("-");
      let sensor_id = splited_name[splited_name.length - 1];
      let corresponding_warning_level_low = getItemFromName(
        theItems,
        "analog-warning_level_low-" + sensor_id
      );
      let corresponding_warning_level_high = getItemFromName(
        theItems,
        "analog-warning_level_high-" + sensor_id
      );
      let corresponding_alarm_level_high = getItemFromName(
        theItems,
        "analog-alarm_level_high-" + sensor_id
      );
      ModifiedMustBeLowerOrEqual(
        theModifiedItem,
        corresponding_warning_level_low
      );
      ModifiedMustBeLowerOrEqual(
        corresponding_warning_level_low,
        corresponding_warning_level_high
      );
      ModifiedMustBeLowerOrEqual(
        corresponding_warning_level_high,
        corresponding_alarm_level_high
      );
    } else if (modif.includes("analog-warning_level_low-")) {
      let splited_name = modif.split("-");
      let sensor_id = splited_name[splited_name.length - 1];
      let corresponding_alarm_level_low = getItemFromName(
        theItems,
        "analog-alarm_level_low-" + sensor_id
      );
      let corresponding_warning_level_high = getItemFromName(
        theItems,
        "analog-warning_level_high-" + sensor_id
      );
      let corresponding_alarm_level_high = getItemFromName(
        theItems,
        "analog-alarm_level_high-" + sensor_id
      );
      ModifiedMustBeHigherOrEqual(
        theModifiedItem,
        corresponding_alarm_level_low
      );
      ModifiedMustBeLowerOrEqual(
        theModifiedItem,
        corresponding_warning_level_high
      );
      ModifiedMustBeLowerOrEqual(
        corresponding_warning_level_high,
        corresponding_alarm_level_high
      );
    } else if (modif.includes("analog-warning_level_high-")) {
      let splited_name = modif.split("-");
      let sensor_id = splited_name[splited_name.length - 1];
      let corresponding_alarm_level_low = getItemFromName(
        theItems,
        "analog-alarm_level_low-" + sensor_id
      );
      let corresponding_warning_level_low = getItemFromName(
        theItems,
        "analog-warning_level_low-" + sensor_id
      );
      let corresponding_alarm_level_high = getItemFromName(
        theItems,
        "analog-alarm_level_high-" + sensor_id
      );
      ModifiedMustBeLowerOrEqual(
        theModifiedItem,
        corresponding_alarm_level_high
      );
      ModifiedMustBeHigherOrEqual(
        theModifiedItem,
        corresponding_warning_level_low
      );
      ModifiedMustBeHigherOrEqual(
        corresponding_warning_level_low,
        corresponding_alarm_level_low
      );
    } else if (modif.includes("analog-alarm_level_high-")) {
      let splited_name = modif.split("-");
      let sensor_id = splited_name[splited_name.length - 1];
      let corresponding_warning_level_low = getItemFromName(
        theItems,
        "analog-warning_level_low-" + sensor_id
      );
      let corresponding_alarm_level_low = getItemFromName(
        theItems,
        "analog-alarm_level_low-" + sensor_id
      );
      let corresponding_warning_level_high = getItemFromName(
        theItems,
        "analog-warning_level_high-" + sensor_id
      );
      ModifiedMustBeHigherOrEqual(
        theModifiedItem,
        corresponding_warning_level_high
      );
      ModifiedMustBeHigherOrEqual(
        corresponding_warning_level_high,
        corresponding_warning_level_low
      );
      ModifiedMustBeHigherOrEqual(
        corresponding_warning_level_low,
        corresponding_alarm_level_low
      );
    }
  }

  function updateMaxWithMinValueWaterLevelSensor(
    theModifiedItem: Item,
    theItems: Array<Item>,
    modif: string
  ): void {
    if (modif.includes("water_level-warning_level-")) {
      let splited_name = modif.split("-");
      let sensor_id = splited_name[splited_name.length - 1];
      let corresponding_alarm_level = getItemFromName(
        theItems,
        "water_level-alarm_level-" + sensor_id
      );
      ModifiedMustBeLowerOrEqual(theModifiedItem, corresponding_alarm_level);
    } else if (modif.includes("water_level-alarm_level-")) {
      let splited_name = modif.split("-");
      let sensor_id = splited_name[splited_name.length - 1];
      let corresponding_warning_level = getItemFromName(
        theItems,
        "water_level-warning_level-" + sensor_id
      );
      ModifiedMustBeHigherOrEqual(theModifiedItem, corresponding_warning_level);
    }
  }

  const update_water_level_sensor = (item: Item): void => {
    const sensor_id = Number(item.name.split("-")[2]);
    const sensor = find_water_level_sensor_from_sensor_id(station, sensor_id);
    if (sensor?.id === sensor_id) {
      if (item.name.includes("name")) {
        sensor.name = item.value;
      } else if (item.name.includes("warning_level")) {
        if (
          is_station_pap(station) &&
          sensor.type === "downstream" &&
          sensor.depth === 0
        ) {
          sensor.warning_level = -1;
        } else {
          sensor.warning_level = item.value;
        }
      } else if (item.name.includes("alarm_level")) {
        if (
          is_station_pap(station) &&
          sensor.type === "downstream" &&
          sensor.depth === 0
        ) {
          sensor.alarm_level = -1;
        } else {
          sensor.alarm_level = item.value;
        }
      } else if (item.name.includes("trigger_under_water")) {
        sensor.trigger_under_water = item.value;
      }
    }
  };

  const update_analog_sensor = (item: Item): void => {
    const sensor_id = Number(item.name.split("-")[2]);
    const sensor = find_analog_sensor_from_sensor_id(station, sensor_id);
    if (sensor?.id === sensor_id) {
      if (item.name.includes("name")) {
        sensor.name = item.value;
      } else if (item.name.includes("alarm_level_low")) {
        sensor.alarm_level_low = item.value;
      }
      if (item.name.includes("alarm_level_high")) {
        sensor.alarm_level_high = item.value;
      }
      if (item.name.includes("warning_level_low")) {
        sensor.warning_level_low = item.value;
      }
      if (item.name.includes("warning_level_high")) {
        sensor.warning_level_high = item.value;
      }
    }
  };

  const update_contacts = (item: Item): void => {
    const contact_id = Number(item.name.split("-")[2]);
    const the_contact = station.contacts.find(
      (contact) => contact.user_id === contact_id
    );
    if (the_contact !== undefined) {
      //console.log(displayName, "The contact designed is:"+the_contact.email)
      if (item.name.includes("embacle_warning")) {
        the_contact.embacle_warning = item.value;
      } else if (item.name.includes("embacle_alarm")) {
        the_contact.embacle_alarm = item.value;
      } else if (item.name.includes("alimentation_warning")) {
        the_contact.alimentation_warning = item.value;
      } else if (item.name.includes("alimentation_alarm")) {
        the_contact.alimentation_alarm = item.value;
      } else if (item.name.includes("warning")) {
        the_contact.warning = item.value;
      } else if (item.name.includes("alarm_pkd")) {
        if (enablePKD) {
          the_contact.alarm_pkd = item.value;
        } else {
          //Disable PKD alarm if pkd is disabled
          the_contact.alarm_pkd = false;
        }
      } else if (item.name.includes("alarm")) {
        the_contact.alarm = item.value;
      }
    }
  };

  const validateForm = () => {
    items.forEach((one) => {
      if (one.value !== one.initial_value || one.name.includes("pkd")) {
        console.log(
          displayName,
          "Item:" +
            one.name +
            " has changed :" +
            one.initial_value +
            "=>" +
            one.value
        );
        if (one.name === "station-name") {
          station.name = one.value;
        } else if (one.name === "station-migration_date_start") {
          station.migration_date_start = one.value;
        } else if (one.name === "station-migration_date_end") {
          station.migration_date_end = one.value;
        } else if (one.name === "station-in_migration_forced") {
          station.in_migration_forced = one.value;
        } else if (one.name === "station-alarm_pkd_days") {
          const pkd_fault_trigger = station_get_pkd_fault_trigger(station);
          if (enablePKD) {
            if (!pkd_fault_trigger) {
              console.log(displayName, "create a new alert alarm_pkd_days");
              let one_fault_trigger = new FaultTrigger(0);
              one_fault_trigger.station_id = station.id;
              one_fault_trigger.type = "pkd";
              one_fault_trigger.alarm_days = one.value;
              station.faulttriggers = [one_fault_trigger];
            } else {
              console.log(displayName, "update alert alarm_pkd_days");
              pkd_fault_trigger.alarm_days = one.value;
            }
          } else {
            console.log(displayName, "update alert alarm_pkd_days to delete");
            if (pkd_fault_trigger) {
              pkd_fault_trigger.id = 0;
              pkd_fault_trigger.alarm_days = 0;
              pkd_fault_trigger.alarm_level_high = 0;
            }
          }
        } else if (one.name === "station-alarm_pkd_temperature") {
          const pkd_fault_trigger = station_get_pkd_fault_trigger(station);
          if (enablePKD) {
            if (!pkd_fault_trigger) {
              console.log(
                displayName,
                "create a new alert alarm_pkd_temperature"
              );
              let one_fault_trigger = new FaultTrigger(0);
              one_fault_trigger.station_id = station.id;
              one_fault_trigger.type = "pkd";
              one_fault_trigger.alarm_level_high = one.value;
              station.faulttriggers = [one_fault_trigger];
            } else {
              console.log(displayName, "update alert alarm_pkd_temperature");
              pkd_fault_trigger.alarm_level_high = one.value;
            }
          } else {
            console.log(
              displayName,
              "update alert alarm_pkd_temperature to delete"
            );
            if (pkd_fault_trigger) {
              pkd_fault_trigger.id = 0;
              pkd_fault_trigger.alarm_days = 0;
              pkd_fault_trigger.alarm_level_high = 0;
            }
          }
        }
        if (one.name.includes("water_level")) {
          update_water_level_sensor(one);
        }
        if (one.name.includes("analog")) {
          update_analog_sensor(one);
        }
        if (one.name.includes("contact")) {
          update_contacts(one);
        }
      }
    });
    update_fault_trigger_but_pkd();
    return true;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isFormValid = validateForm();
    if (isFormValid) {
      console.log(displayName, "Setup form is valid");
      setUpdating(true);
      updateStation();
    }
  };

  function alarm_water_level_sensor(
    uSensors: Array<WaterLevelSensor> | undefined,
    dsensors: Array<WaterLevelSensor> | undefined,
    uType: string,
    name: string
  ) {
    /*
     * type :
     * -> general : standard waterlevel display
     * -> upstream : show alimentation
     * -> downstream : show embacle
     */
    if (uSensors === undefined) {
      return;
    }
    if (uType === "downstream" && dsensors === undefined) {
      console.log(displayName, "Please provide upstream/downstream sensors");
      return;
    }

    function getCorrectName(type: string) {
      if (type === "upstream") {
        return "Profondeur :";
      } else if (type === "downstream") {
        return "Différence de niveau :";
      }
      return "Nom :";
    }

    function getStateOfDSensor(pos: number): boolean {
      if (dsensors && dsensors.length > pos + 1) {
        if (dsensors[pos]) {
          return dsensors[pos].current_value;
        }
      }
      return false;
    }

    function getStateOfUSensor(pos: number): boolean {
      if (uSensors && uSensors.length > pos + 1) {
        if (uSensors[pos]) {
          return uSensors[pos].current_value;
        }
      }
      return false;
    }

    function getCorrectSensor(
      the_type: string
    ): WaterLevelSensor[] | undefined {
      if (the_type === "downstream") {
        return dsensors;
      }
      return uSensors;
    }

    let ptSensor = getCorrectSensor(uType);
    if (uType !== "general") {
      ptSensor = ptSensor?.sort((a, b) => (a.depth < b.depth ? 1 : -1));
    }

    function displayOneAlarmWaterLevelSensor(
      sensor: WaterLevelSensor,
      pos: number
    ) {
      return (
        <div
          key={"sensor-station-" + station.id + "-wlsensor-" + sensor.id}
          className="panel-setup-station-line-content-in"
        >
          <input
            className="panel-setup-station-line-content-name"
            type="text"
            value={
              uType === "general"
                ? sensor.name
                  ? sensor.name
                  : ""
                : sensor.depth + "cm"
            }
            disabled
          />
          <div className="panel-setup-station-line-content-waterlevel-live">
            <div className="panel-setup-station-line-content-waterlevel-live-value">
              {uType === "downstream" && (
                <div
                  className={
                    return_color_for_water_level_from_bool(
                      getStateOfUSensor(pos)
                    ) + "led led-sensor"
                  }
                ></div>
              )}
            </div>
            <div className="panel-setup-station-line-content-waterlevel-live-value" />
            <div className="panel-setup-station-line-content-waterlevel-live-value">
              {uType === "downstream" ? (
                <div
                  className={
                    return_color_for_water_level_from_bool(
                      getStateOfDSensor(pos)
                    ) + "led led-sensor"
                  }
                ></div>
              ) : (
                <div
                  className={
                    return_color_for_water_level_from_bool(
                      sensor.current_value
                    ) + "led led-sensor"
                  }
                ></div>
              )}
            </div>
            <div className="panel-setup-station-line-content-waterlevel-live-days">
              {sensor.last_update}
            </div>
          </div>
          {uType !== "downstream" ? (
            <div
              data-tip={
                getItemFromName(
                  items,
                  "water_level-trigger_under_water-" + sensor.id
                ).value
                  ? "En eau"
                  : "Hors d'eau"
              }
              className="panel-setup-station-line-content-waterlevel-state"
              onClick={() =>
                current_user_can_modify &&
                toggleItemFromName(
                  items,
                  "water_level-trigger_under_water-" + sensor.id,
                  setItems
                )
              }
            >
              {getItemFromName(
                items,
                "water_level-trigger_under_water-" + sensor.id
              ).value
                ? return_logo_from_type(
                    "water_level-under_water",
                    "logo-sensor-half-size-oblon-png"
                  )
                : return_logo_from_type(
                    "water_level-over_water",
                    "logo-sensor-half-size-oblon-png"
                  )}
            </div>
          ) : (
            <div className="panel-setup-station-line-content-waterlevel-state" />
          )}
          {is_station_pap(station) &&
          sensor.type === "downstream" &&
          sensor.depth >= 0 ? (
            <div className="panel-setup-station-line-content-waterlevel-warning-alarm" />
          ) : (
            <div className="panel-setup-station-line-content-waterlevel-warning-alarm">
              {uiNumericInput(
                "days_warning_alarm",
                "water_level-warning_level-" + sensor.id,
                getItemFromName(items, "water_level-warning_level-" + sensor.id)
                  .value,
                handleOnChangeWaterLevelSensor,
                !current_user_can_modify
              )}
              <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text">
                {getItemFromName(
                  items,
                  "water_level-warning_level-" + sensor.id
                ).value > 1
                  ? "jours"
                  : "jour"}
              </div>
            </div>
          )}
          {is_station_pap(station) &&
          sensor.type === "downstream" &&
          sensor.depth >= 0 ? (
            <div className="panel-setup-station-line-content-waterlevel-warning-alarm" />
          ) : (
            <div className="panel-setup-station-line-content-waterlevel-warning-alarm">
              {uiNumericInput(
                "days_warning_alarm",
                "water_level-alarm_level-" + sensor.id,
                getItemFromName(items, "water_level-alarm_level-" + sensor.id)
                  .value,
                handleOnChangeWaterLevelSensor,
                !current_user_can_modify
              )}
              <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text">
                {getItemFromName(items, "water_level-alarm_level-" + sensor.id)
                  .value > 1
                  ? "jours"
                  : "jour"}
              </div>
            </div>
          )}
        </div>
      );
    }

    function displayOneAlarmWaterLevelSensorHelp(
      sensor: WaterLevelSensor,
      pos: number,
      uType: string
    ) {
      const trigger_under_water = getItemFromName(
        items,
        "water_level-trigger_under_water-" + sensor.id
      ).value;
      const warning_level = getItemFromName(items, "water_level-warning_level-" + sensor.id).value;
      const alarm_level = getItemFromName(items, "water_level-alarm_level-" + sensor.id).value;
      if(uType!=="general"){return}
      if(warning_level === -1 && alarm_level === -1){
        return(
          <div className="panel-setup-station-line-content-in-help">Aucune alerte n'est paramètrée.</div>
        );
      }
      return(
      <div className="panel-setup-station-line-content-in-help">
        {(warning_level===0 && alarm_level!==0)?(<div className="panel-setup-station-line-content-in-help">Une alerte <b className="warning-font">Vigilance</b> se déclenchera <strong>immédiatement</strong> lorsque le capteur de niveau d'eau {sensor.name} sera <strong>{(trigger_under_water)?("Hors d'eau"):("En eau")}</strong>.</div>):undefined}
        {(alarm_level===0)?(<div className="panel-setup-station-line-content-in-help">Une alerte <b className="alarm-font">Urgence</b> se déclenchera <strong>immédiatement</strong> lorsque le capteur de niveau d'eau {sensor.name} sera <strong>{(trigger_under_water)?("Hors d'eau"):("En eau")}</strong>.</div>):undefined}
        {(warning_level>0)?(<div className="panel-setup-station-line-content-in-help">Une alerte <b className="warning-font">Vigilance</b> se déclenchera lorsque le capteur de niveau d'eau {sensor.name} sera <strong>{(trigger_under_water)?("Hors d'eau"):("En eau")}</strong> et que l'anomalie se maintiendra pendant <strong>{warning_level} jour{(warning_level>1)?"s":""}</strong>.</div>):undefined}
        {(alarm_level>0)?(<div className="panel-setup-station-line-content-in-help">Une alerte <b className="alarm-font">Urgence</b> se déclenchera lorsque le capteur de niveau d'eau {sensor.name} sera <strong>{(trigger_under_water)?("Hors d'eau"):("En eau")}</strong> et que l'anomalie se maintiendra pendant <strong>{alarm_level} jour{(alarm_level>1)?"s":""}</strong>.</div>):undefined}
      </div>
      );
    }

    return (
      <Fragment>
        <div className="panel-setup-station-line">
          {alarm_display_logo_and_title(uType, name)}
          <div className="panel-setup-station-line-content">
            {/* Static */}
            <div className="panel-setup-station-line-content-in">
              <div className="panel-setup-station-line-content-name">
                {getCorrectName(uType)}
              </div>
              <div className="panel-setup-station-line-content-waterlevel-live">
                Live :
              </div>
              <div
                data-tip="Conditions normales de fonctionnement"
                className="panel-setup-station-line-content-waterlevel-state"
              >
                {uType !== "downstream" && "État Normal:"}
              </div>
              <div className="panel-setup-station-line-content-waterlevel-warning-alarm">
                <div className="panel-setup-station-line-content-waterlevel-warning-alarm-input">
                  <div
                    data-tip={"Niveau de Vigilance"}
                    className={"orangeled led-sensor"}
                  ></div>
                </div>
                <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text" />
              </div>
              <div className="panel-setup-station-line-content-waterlevel-warning-alarm">
                <div className="panel-setup-station-line-content-waterlevel-warning-alarm-input">
                  <div
                    data-tip={"Niveau d'Urgence"}
                    className={"redled led-sensor"}
                  ></div>
                </div>
                <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text" />
              </div>
            </div>
            {/* Dynamic */}
            {ptSensor?.map((sensor, pos) =>
              displayOneAlarmWaterLevelSensor(sensor, pos)
            )}
          </div>
        </div>
        {/* Help Waterlevel sensor */}
        {help ? (
          <div className="panel-setup-station-line-help">
            {ptSensor?.map((sensor, pos) =>
              displayOneAlarmWaterLevelSensorHelp(sensor, pos, uType)
            )}
          </div>
        ) : undefined}
      </Fragment>
    );
  }

  function generateFaultTriggerFromItems(
    items: Array<Item>,
    IdInItem: string
  ): FaultTrigger {
    const newLiquidLevelFaultTrigger = new FaultTrigger();
    const basename = "fault_trigger-" + IdInItem + "-liquid_level-";
    const the_trigger_high = getItemFromName(items, basename + "trigger_high");
    let the_alarm_level_low_value: number = -1;
    let the_alarm_level_high_value: number = -1;
    if (the_trigger_high.value) {
      the_alarm_level_high_value = getItemFromName(
        items,
        basename + "alarm_level_high"
      ).value;
    } else {
      the_alarm_level_low_value = getItemFromName(
        items,
        basename + "alarm_level_low"
      ).value;
    }
    const the_alarm_days = getItemFromName(items, basename + "alarm_days");
    const the_warning_days = getItemFromName(items, basename + "warning_days");
    const the_name = getItemFromName(items, basename + "name");
    newLiquidLevelFaultTrigger.alarm_level_low = the_alarm_level_low_value;
    newLiquidLevelFaultTrigger.alarm_level_high = the_alarm_level_high_value;
    newLiquidLevelFaultTrigger.alarm_days = the_alarm_days?.value;
    newLiquidLevelFaultTrigger.warning_days = the_warning_days?.value;
    newLiquidLevelFaultTrigger.name = the_name?.value;
    newLiquidLevelFaultTrigger.warning_level_low = -1;
    newLiquidLevelFaultTrigger.warning_level_high = -1;
    newLiquidLevelFaultTrigger.type = "liquid_level";
    newLiquidLevelFaultTrigger.station_id = station.id;
    const liquidLevelSensors = get_all_typed_analog_sensors_from_station(
      station,
      "liquid_level"
    );
    if (liquidLevelSensors && liquidLevelSensors[0]) {
      newLiquidLevelFaultTrigger.analog_sensor_id = liquidLevelSensors[0].id;
    }
    return newLiquidLevelFaultTrigger;
  }

  function update_fault_trigger_but_pkd() {
    let faultTriggerToRemove: Array<string> = [];
    station.faulttriggers?.forEach((one) =>
      faultTriggerToRemove.push(one.id.toString())
    );
    debug("Already existing faultTrigger in station:", faultTriggerToRemove);
    getLiquidLevelFaultTriggerIdsInItems().forEach((oneFTId) => {
      console.log(displayName, "Handle FaultTrigger named :", oneFTId);
      if (Number(oneFTId)) {
        console.log(displayName, "Existing FaultTrigger : ", oneFTId);
        //Update faultTriggerToRemove to not remove this one
        faultTriggerToRemove = faultTriggerToRemove.filter(
          (oneId) => oneId !== oneFTId.toString()
        );
        //Now update value
        const theExistingFaultTrigger = station.faulttriggers.find(
          (one) => one.id.toString() === oneFTId
        );
        if (theExistingFaultTrigger) {
          const basename = "fault_trigger-" + oneFTId + "-liquid_level-";
          const trigger_high = getItemFromName(
            items,
            basename + "trigger_high"
          ).value;
          theExistingFaultTrigger.name = getItemFromName(
            items,
            basename + "name"
          ).value;
          theExistingFaultTrigger.warning_level_high = -1;
          theExistingFaultTrigger.warning_level_low = -1;
          if (trigger_high) {
            theExistingFaultTrigger.alarm_level_low = -1;
            theExistingFaultTrigger.alarm_level_high = getItemFromName(
              items,
              basename + "alarm_level_high"
            ).value;
          } else {
            theExistingFaultTrigger.alarm_level_low = getItemFromName(
              items,
              basename + "alarm_level_low"
            ).value;
            theExistingFaultTrigger.alarm_level_high = -1;
          }
          theExistingFaultTrigger.alarm_days = getItemFromName(
            items,
            basename + "alarm_days"
          ).value;
          theExistingFaultTrigger.warning_days = getItemFromName(
            items,
            basename + "warning_days"
          ).value;
        }
      } else {
        console.log(displayName, "New FaultTrigger : ", oneFTId);
        const newFT = generateFaultTriggerFromItems(items, oneFTId);
        station.faulttriggers.push(newFT);
      }
    });
    //Now remove already existing Faulttrigger asked to be removed
    debug("Existing faultTrigger in station to remove:", faultTriggerToRemove);
    debug("Before station.faulttriggers:", station.faulttriggers);
    station.faulttriggers = station.faulttriggers?.filter(
      (oneFT) => !faultTriggerToRemove.includes(oneFT.id.toString())
    );
    debug("After station.faulttriggers:", station.faulttriggers);
  }

  function addNewLiquiLevelFaultTrigger() {
    /* Must make sure name are different */
    /* We only can add one if name is set */
    console.log("======>addNewLiquiLevelFaultTrigger");
    const newLiquidLevelFaultTrigger = new FaultTrigger();
    newLiquidLevelFaultTrigger.type = "liquid_level";
    let newItemArray: Array<Item> = [];
    //setNewLiquidLevelFaultTriggerIdArray
    //let newIdLetter = "A";
    const newIdLetter = String.fromCharCode(
      "A".charCodeAt(0) + newLiquidLevelFaultTriggerIdArray.length
    );
    newLiquidLevelFaultTrigger.name = "Alerte de niveau " + newIdLetter;
    newLiquidLevelFaultTrigger.warning_level_high = -1; //not used
    newLiquidLevelFaultTrigger.warning_level_low = -1; //not used
    newLiquidLevelFaultTrigger.alarm_level_high = 100;
    newLiquidLevelFaultTrigger.alarm_level_low = -1; // Initialized deactivated
    newLiquidLevelFaultTrigger.warning_days = -1;
    newLiquidLevelFaultTrigger.alarm_days = -1;
    setOneFaultTrigger(
      newItemArray,
      newLiquidLevelFaultTrigger,
      newIdLetter,
      undefined
    );
    setNewLiquidLevelFaultTriggerIdArray([
      ...newLiquidLevelFaultTriggerIdArray,
      ...[newIdLetter],
    ]);
    setItems([...items, ...newItemArray]);
  }

  function toggleTriggerHighOfTriggerFault(
    items: Array<Item>,
    oneIdInItem: string,
    setItems: any
  ) {
    const basename = "fault_trigger-" + oneIdInItem + "-liquid_level-";
    let newitems = toggleItemFromName(
      items,
      basename + "trigger_high",
      undefined
    );
    //revert high low
    const alarm_level_high_value = getItemFromName(
      items,
      basename + "alarm_level_high"
    ).value;
    const alarm_level_low_value = getItemFromName(
      items,
      basename + "alarm_level_low"
    ).value;
    newitems = updateItemFromNameWithValueAndSetter(
      items,
      basename + "alarm_level_high",
      alarm_level_low_value,
      undefined,
      undefined
    );
    newitems = updateItemFromNameWithValueAndSetter(
      items,
      basename + "alarm_level_low",
      alarm_level_high_value,
      undefined,
      undefined
    );
    setItems(newitems);
  }

  function oneLiquidLevelFaultTriggerDisplayParameters(oneIdInItem: string) {
    debugMore(
      "oneLiquidLevelFaultTriggerDisplayParameters oneIdInItem:",
      oneIdInItem
    );
    const basename = "fault_trigger-" + oneIdInItem + "-liquid_level-";
    const trigger_high = getItemFromName(
      items,
      basename + "trigger_high"
    ).value;
    const fault_status = getItemFromName(
      items,
      basename + "fault_status"
    ).value;
    return (
      <div
        key={"liquidlevelFaultTrigger-" + oneIdInItem}
        className={"panel-setup-station-line-content-in"}
      >
        {/* Add/Remove alerte */}
        <div className="panel-setup-station-line-content-analog-add-remove">
          <FontAwesomeIcon
            icon={faCircleXmark}
            size="lg" /* inverse={true} */
            onClick={() => {
              removeOneFaultTrigger(
                items,
                setItems,
                oneIdInItem,
                "liquid_level"
              );
            }}
          />
        </div>
        {/* Alerte name */}
        <div className="panel-setup-station-line-content-name">
          <div className="panel-setup-station-sensor-input-text">
            <input
              id={basename + "name"}
              type="text"
              className={
                "stay-in-div " +
                (fault_status === "orange" ? "border-solid-orange" : "") +
                (fault_status === "red" ? "border-solid-red" : "")
              }
              disabled={!current_user_can_modify}
              value={getItemFromName(items, basename + "name").value}
              onChange={(e) => handleGenericChange(e, items, setItems)}
            />
          </div>
        </div>
        {/* Alerte conditions over/under */}
        <div className="panel-setup-station-line-content-analog-conditions">
          <div
            data-tip={trigger_high ? "Au dessus" : "En dessous"}
            className="panel-setup-station-line-content-waterlevel-state"
            onClick={() =>
              current_user_can_modify &&
              toggleTriggerHighOfTriggerFault(items, oneIdInItem, setItems)
            }
          >
            {trigger_high
              ? return_logo_from_type(
                  "trigger_high",
                  "logo-sensor-half-size-oblon-png"
                )
              : return_logo_from_type(
                  "trigger_low",
                  "logo-sensor-half-size-oblon-png"
                )}
          </div>
        </div>
        {/* Alerte water_height */}
        <div className="panel-setup-station-line-content-depth-alarm-liquid-level">
          {uiNumericInput(
            "liquid_level",
            trigger_high
              ? basename + "alarm_level_high"
              : basename + "alarm_level_low",
            getItemFromName(
              items,
              trigger_high
                ? basename + "alarm_level_high"
                : basename + "alarm_level_low"
            ).value,
            handleOnChangeAnalogSensor,
            !current_user_can_modify,
            false
          )}
        </div>
        {/* Alerte Warning days */}
        <div className="panel-setup-station-line-content-analog-warning-alarm-liquid-level">
          {uiNumericInput(
            "days_warning_alarm",
            basename + "warning_days",
            getItemFromName(items, basename + "warning_days").value,
            handleOnChangeAnalogSensor,
            !current_user_can_modify
          )}
          <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text">
            {getItemFromName(items, basename + "warning_days").value > 1
              ? "jours"
              : "jour"}
          </div>
        </div>
        {/* Alerte Alarm days */}
        <div className="panel-setup-station-line-content-analog-warning-alarm-liquid-level">
          {uiNumericInput(
            "days_warning_alarm",
            basename + "alarm_days",
            getItemFromName(items, basename + "alarm_days").value,
            handleOnChangeAnalogSensor,
            !current_user_can_modify
          )}
          <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text">
            {getItemFromName(items, basename + "alarm_days").value > 1
              ? "jours"
              : "jour"}
          </div>
        </div>
        <ReactTooltip type="light" />
      </div>
    );
  }
  function checkLiquidLevelConsistency(oneIdInItem: string) {
    const basename = "fault_trigger-" + oneIdInItem + "-liquid_level-";
    const warning_days = getItemFromName(
      items,
      basename + "warning_days"
    ).value;
    const alarm_days = getItemFromName(items, basename + "alarm_days").value;
    //Check we won't get warning after alarm or in same time
    //let got_a_modification: boolean = false;
    if (alarm_days !== -1 && alarm_days <= warning_days) {
      updateItemFromNameWithValueAndSetter(
        items,
        basename + "warning_days",
        -1,
        setItems
      );
    }
  }

  function oneLiquidLevelFaultTriggerDisplayHelp(oneIdInItem: string) {
    const basename = "fault_trigger-" + oneIdInItem + "-liquid_level-";
    const trigger_high = getItemFromName(
      items,
      basename + "trigger_high"
    ).value;
    const trigger_value = getItemFromName(
      items,
      trigger_high
        ? basename + "alarm_level_high"
        : basename + "alarm_level_low"
    ).value;
    const warning_days = getItemFromName(
      items,
      basename + "warning_days"
    ).value;
    const alarm_days = getItemFromName(items, basename + "alarm_days").value;
    const alarm_name = getItemFromName(items, basename + "name").value;
    return (
      <Fragment>
        {warning_days === 0 ? (
          <p className="panel-setup-station-line-content-in-help">
            <b>{alarm_name ? '"' + alarm_name + '"' : ""}</b> : Une alerte
            &nbsp;<b className="warning-font">Vigilance</b>&nbsp; se déclanchera
            aussitôt que le niveau d'eau passera &nbsp;
            <b>{trigger_high ? "au dessus" : "en dessous"}</b>&nbsp; d'une
            hauteur de &nbsp;<b>{trigger_value}cm</b>&nbsp;.
          </p>
        ) : undefined}
        {warning_days > 0 ? (
          <p className="panel-setup-station-line-content-in-help">
            <b>{alarm_name ? '"' + alarm_name + '"' : ""}</b> : Une alerte
            &nbsp;<b className="warning-font">Vigilance</b>&nbsp; se déclanchera
            aussitôt que le niveau d'eau passera &nbsp;
            <b>{trigger_high ? "au dessus" : "en dessous"}</b>&nbsp; d'une
            hauteur de &nbsp;<b>{trigger_value}cm</b>&nbsp; pendant &nbsp;
            <b>
              {warning_days} jour{warning_days > 1 ? "s" : ""} consécutif
              {warning_days > 1 ? "s" : ""}
            </b>
            .
          </p>
        ) : undefined}
        {alarm_days === 0 ? (
          <p className="panel-setup-station-line-content-in-help">
            <b>{alarm_name ? '"' + alarm_name + '"' : ""}</b> : Une alerte
            &nbsp;<b className="alarm-font">Urgence</b>&nbsp; se déclanchera
            aussitôt que le niveau d'eau passera &nbsp;
            <b>{trigger_high ? "au dessus" : "en dessous"}</b>&nbsp; d'une
            hauteur de &nbsp;<b>{trigger_value}cm</b>&nbsp;.
          </p>
        ) : undefined}
        {alarm_days > 0 ? (
          <p className="panel-setup-station-line-content-in-help">
            <b>{alarm_name ? '"' + alarm_name + '"' : ""}</b> : Une alerte
            &nbsp;<b className="alarm-font">Urgence</b>&nbsp; se déclanchera
            aussitôt que le niveau d'eau passera &nbsp;
            <b>{trigger_high ? "au dessus" : "en dessous"}</b>&nbsp; d'une
            hauteur de &nbsp;<b>{trigger_value}cm</b>&nbsp; pendant &nbsp;
            <b>
              {warning_days} jour{warning_days > 1 ? "s" : ""} consécutif
              {warning_days > 1 ? "s" : ""}
            </b>
            .
          </p>
        ) : undefined}
        {alarm_days === -1 && warning_days === -1 ? (
          <p className="panel-setup-station-line-content-in-help alarm-font text-bold-font">
            L'alerte {alarm_name ? '"' + alarm_name + '"' : ""} ne déclenchera
            jamais, il faut autre chose que le status "Aucun"
          </p>
        ) : undefined}
      </Fragment>
    );
  }

  function alarm_liquid_level_sensor(station: Station) {
    const type = "liquid_level";
    if (
      !get_all_typed_analog_sensors_from_station(station, "liquid_level")
        ?.length
    ) {
      return;
    }
    return (
      <Fragment>
        <div
          key={"sensor-station-" + station.id + "-liquidlevelsensor"}
          className="panel-setup-station-line"
        >
          {alarm_display_logo_and_title(type, return_type_test(type))}
          <div className="panel-setup-station-line-content">
            {/* Header */}
            {getLiquidLevelFaultTriggerIdsInItems().length > 0 ? (
              <div className="panel-setup-station-line-content-in">
                {/* Add/Remove alerte */}
                <div className="panel-setup-station-line-content-analog-add-remove">
                  &nbsp;
                </div>
                {/* Alerte name */}
                <div className="panel-setup-station-line-content-name">
                  Nom de l'alerte
                </div>
                {/* Alerte conditions over/under */}
                <div className="panel-setup-station-line-content-analog-conditions">
                  Conditions
                </div>
                {/* Alerte water_height */}
                <div className="panel-setup-station-line-content-depth-alarm-liquid-level">
                  {/* Hauteur totale : */}
                  Hauteur (cm)
                </div>
                {/* Alerte Warning days */}
                <div className="panel-setup-station-line-content-analog-warning-alarm-liquid-level">
                  <div
                    data-tip={"Niveau de Vigilance"}
                    className={"orangeled led-sensor"}
                  ></div>
                  <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text">
                    &nbsp;
                  </div>
                </div>
                {/* Alerte Alarm days */}
                <div className="panel-setup-station-line-content-analog-warning-alarm-liquid-level">
                  <div
                    data-tip={"Niveau d'Urgence"}
                    className={"redled led-sensor"}
                  ></div>
                  <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text">
                    &nbsp;
                  </div>
                </div>
              </div>
            ) : undefined}
            {/* DYNAMIC */}
            {getLiquidLevelFaultTriggerIdsInItems().map((oneIdInItem) => {
              return oneLiquidLevelFaultTriggerDisplayParameters(oneIdInItem);
            })}
            {/* STATIC TO ADD */}
            <div className="panel-setup-station-line-content-in">
              {/* Add/Remove alerte */}
              <div className="panel-setup-station-line-content-analog-add-remove">
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  size="lg" /* inverse={true} */
                  onClick={() => addNewLiquiLevelFaultTrigger()}
                />
              </div>
              {/* Alerte name */}
              <div className="panel-setup-station-line-content-name">
                Ajouter une alerte
              </div>
              {/* Alerte conditions over/under */}
              <div className="panel-setup-station-line-content-analog-conditions">
                &nbsp;
              </div>
              {/* Alerte water_height */}
              <div className="panel-setup-station-line-content-depth-alarm-liquid-level">
                &nbsp;
              </div>
              {/* Alerte Warning days */}
              <div className="panel-setup-station-line-content-analog-warning-alarm-liquid-level">
                &nbsp;
              </div>
              {/* Alerte Alarm days */}
              <div className="panel-setup-station-line-content-analog-warning-alarm-liquid-level">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        {/* Help liquid_sensor */}
        {help ? (
          <div
            key={"sensor-station-" + station.id + "-liquidlevelsensor-help"}
            className="panel-setup-station-line-help"
          >
            {getLiquidLevelFaultTriggerIdsInItems().map((oneIdInItem) => {
              return oneLiquidLevelFaultTriggerDisplayHelp(oneIdInItem);
            })}
          </div>
        ) : undefined}
      </Fragment>
    );
  }

  function oneAnalogSensorDisplayParameters(sensor: AnalogSensor) {
    return (
      <div
        key={"sensor-station-" + station.id + "-analogsensor-" + sensor.id}
        className="panel-setup-station-line-content-in"
      >
        <div className="panel-setup-station-line-content-name">
          <div className="panel-setup-station-sensor-input-text full-width">
            <input
              id={
                "sensor-station-" +
                station.id +
                "-sensor-" +
                sensor.id +
                "-name"
              }
              type="text"
              className="stay-in-div"
              disabled={true}
              value={sensor.name ? sensor.name : ""}
            />
          </div>
        </div>
        <div className="panel-setup-station-line-content-analog-warning-alarm">
          {uiNumericInput(
            sensor.type,
            "analog-alarm_level_low-" + sensor.id,
            getItemFromName(items, "analog-alarm_level_low-" + sensor.id).value,
            handleOnChangeAnalogSensor,
            !current_user_can_modify
          )}
        </div>
        <div className="panel-setup-station-line-content-analog-warning-alarm">
          {uiNumericInput(
            sensor.type,
            "analog-warning_level_low-" + sensor.id,
            getItemFromName(items, "analog-warning_level_low-" + sensor.id)
              .value,
            handleOnChangeAnalogSensor,
            !current_user_can_modify
          )}
        </div>
        <div className="panel-setup-station-line-content-analog-live">
          &#60;&nbsp;&nbsp;
          {sensor.type === "temperature" ? sensor.mean : sensor.current_value}
          {return_unity_from_type(sensor.type)}&nbsp;&nbsp;&#62;
        </div>
        <div className="panel-setup-station-line-content-analog-warning-alarm">
          {uiNumericInput(
            sensor.type,
            "analog-warning_level_high-" + sensor.id,
            getItemFromName(items, "analog-warning_level_high-" + sensor.id)
              .value,
            handleOnChangeAnalogSensor,
            !current_user_can_modify
          )}
        </div>
        <div className="panel-setup-station-line-content-analog-warning-alarm">
          {uiNumericInput(
            sensor.type,
            "analog-alarm_level_high-" + sensor.id,
            getItemFromName(items, "analog-alarm_level_high-" + sensor.id)
              .value,
            handleOnChangeAnalogSensor,
            !current_user_can_modify
          )}
        </div>
      </div>
    );
  }

  function oneAnalogSensorDisplayHelp(sensor: AnalogSensor) {
    return (
      <Fragment>
        {/* {warning_days == 0 ? (
          <p className="panel-setup-station-line-content-in-help">
            <b>{alarm_name ? '"' + alarm_name + '"' : ""}</b> : Une alerte
            &nbsp;<b className="warning-font">Vigilance</b>&nbsp; se
            déclanchera aussitôt que le niveau d'eau passera &nbsp;
            <b>{trigger_high ? "au dessus" : "en dessous"}</b>&nbsp; d'une hauteur
            de &nbsp;<b>{trigger_value}cm</b>&nbsp;.
          </p>
        ) : undefined} */}
        <div className="text-center grey-font">
          &#x2B07;{sensor.type}
          {sensor.name ? " : " + sensor.name : ""}&#x2B07;
        </div>
        <p className="panel-setup-station-line-content-in-help">
          Une alerte &nbsp;<b className="alarm-font">Urgence</b>
          &nbsp;se déclanchera dès que la température &nbsp;
          <b>moyenne journalière</b>
          &nbsp; passera &nbsp;<b>au dessus</b>&nbsp; de &nbsp;
          <b>
            {
              getItemFromName(items, "analog-alarm_level_high-" + sensor.id)
                .value
            }
            {return_unity_from_type(sensor.type)}
          </b>
          &nbsp;.
        </p>
        <p className="panel-setup-station-line-content-in-help">
          Une alerte &nbsp;<b className="warning-font">Vigilance</b>
          &nbsp;se déclanchera dès que la température &nbsp;
          <b>moyenne journalière</b>
          &nbsp; passera &nbsp;<b>au dessus</b>&nbsp; de &nbsp;
          <b>
            {
              getItemFromName(items, "analog-warning_level_high-" + sensor.id)
                .value
            }
            {return_unity_from_type(sensor.type)}
          </b>
          &nbsp;.
        </p>
        <p className="panel-setup-station-line-content-in-help">
          Une alerte &nbsp;<b className="warning-font">Vigilance</b>
          &nbsp;se déclanchera dès que la température &nbsp;
          <b>moyenne journalière</b>
          &nbsp; passera &nbsp;<b>en dessous</b>&nbsp; de &nbsp;
          <b>
            {
              getItemFromName(items, "analog-warning_level_low-" + sensor.id)
                .value
            }
            {return_unity_from_type(sensor.type)}
          </b>
          &nbsp;.
        </p>
        <p className="panel-setup-station-line-content-in-help">
          Une alerte &nbsp;<b className="alarm-font">Urgence</b>
          &nbsp;se déclanchera dès que la température &nbsp;
          <b>moyenne journalière</b>
          &nbsp; passera &nbsp;<b>en dessous</b>&nbsp; de &nbsp;
          <b>
            {
              getItemFromName(items, "analog-alarm_level_low-" + sensor.id)
                .value
            }
            {return_unity_from_type(sensor.type)}
          </b>
          &nbsp;.
        </p>
        {sensor.type === "temperature" && pkd_enabled ? (
          enablePKD ? (
            <p className="panel-setup-station-line-content-in-help">
              Une alerte &nbsp;<b className="alarm-font">PKD</b> se déclanchera
              lorsque la température se maintiendra au dessus de{" "}
              <b>
                {getItemFromName(items, "station-alarm_pkd_temperature").value}
                {return_unity_from_type(sensor.type)}
              </b>
              &nbsp; pendant{" "}
              <b>
                {getItemFromName(items, "station-alarm_pkd_days").value} jour
                {getItemFromName(items, "station-alarm_pkd_days").value > 1
                  ? "s"
                  : ""}{" "}
                consécutif
                {getItemFromName(items, "station-alarm_pkd_days").value > 1
                  ? "s"
                  : ""}
              </b>
              &nbsp;.
            </p>
          ) : (
            <p className="panel-setup-station-line-content-in-help">
              Il n'y &nbsp;<b>aucune</b>&nbsp; alerte PKD activée
            </p>
          )
        ) : undefined}
      </Fragment>
    );
  }

  function alarm_analog_sensor(station: Station, type: string) {
    const sensors = get_all_typed_analog_sensors_from_station(station, type);
    if (!sensors?.length) {
      return;
    }
    return (
      <Fragment>
        <div className="panel-setup-station-line">
          {alarm_display_logo_and_title(type, return_type_test(type))}
          <div className="panel-setup-station-line-content">
            {/* STATIC */}
            <div className="panel-setup-station-line-content-in">
              <div className="panel-setup-station-line-content-name">Nom :</div>
              <div className="panel-setup-station-line-content-analog-warning-alarm">
                <div
                  data-tip={"Niveau d'Urgence"}
                  className={"redled led-sensor"}
                ></div>
              </div>
              <div className="panel-setup-station-line-content-analog-warning-alarm">
                <div
                  data-tip={"Niveau de Vigilance"}
                  className={"orangeled led-sensor"}
                ></div>
              </div>
              <div className="panel-setup-station-line-content-analog-live">
                {type === "temperature" ? "Moyenne:" : "Live :"}
              </div>
              <div className="panel-setup-station-line-content-analog-warning-alarm">
                <div
                  data-tip={"Niveau de Vigilance"}
                  className={"orangeled led-sensor"}
                ></div>
              </div>
              <div className="panel-setup-station-line-content-analog-warning-alarm">
                <div
                  data-tip={"Niveau d'Urgence"}
                  className={"redled led-sensor"}
                ></div>
              </div>
            </div>
            {/* DYNAMIC */}
            {sensors?.map((sensor) => oneAnalogSensorDisplayParameters(sensor))}
            {/* DYNAMIC PKD PART */}
            {type === "temperature" && pkd_enabled && (
              <div
                key="station-pkd"
                className="panel-setup-station-line-content-in"
              >
                <div className="panel-setup-station-line-content-name">
                  <div className="panel-setup-station-line-content-name-half">
                    {return_logo_from_type("pkd", "logo-sensor-half-size-png")}
                  </div>
                  <div
                    className="panel-setup-station-line-content-name-half"
                    onClick={() =>
                      current_user_can_modify && setEnablePKD(!enablePKD)
                    }
                  >
                    {return_logo_from_type(
                      enablePKD ? "bp-oblon-selected" : "bp-oblon",
                      "logo-sensor-half-size-oblon-png"
                    )}
                  </div>
                </div>
                <div className="panel-setup-station-line-content-analog-warning-alarm">
                  T°C
                </div>
                <div className="panel-setup-station-line-content-analog-warning-alarm">
                  {uiNumericInput(
                    "temperature",
                    "station-alarm_pkd_temperature",
                    getItemFromName(items, "station-alarm_pkd_temperature")
                      .value,
                    handleOnChangeAnalogSensor,
                    !current_user_can_modify || !enablePKD
                  )}
                </div>
                <div className="panel-setup-station-line-content-analog-warning-alarm-2col">
                  Jours Consécutifs :
                </div>
                <div className="panel-setup-station-line-content-analog-warning-alarm">
                  {uiNumericInput(
                    "days",
                    "station-alarm_pkd_days",
                    getItemFromName(items, "station-alarm_pkd_days").value,
                    handleOnChangeAnalogSensor,
                    !current_user_can_modify || !enablePKD
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Help analog sensor */}
        {help ? (
          <div className="panel-setup-station-line-help">
            {sensors?.map((sensor) => oneAnalogSensorDisplayHelp(sensor))}
          </div>
        ) : undefined}
      </Fragment>
    );
  }

  function display_one_contact(contact: Contact) {
    return (
      <div
        key={
          "line-content-contact-station-" +
          station.id +
          "-contact-" +
          contact.user_id
        }
        className="panel-setup-station-line-content-in"
      >
        <div
          key={"contact-station-" + station.id + "-contact-" + contact.user_id}
          className="panel-setup-station-line-content-in"
        >
          <div className="panel-setup-station-line-content-contact-email">
            <input
              id={"contact-email-" + contact.user_id}
              data-tip={contact.email}
              type="text"
              className="stay-in-div"
              disabled={true}
              value={contact.name}
            />
          </div>
          <div className="panel-setup-station-line-content-contact-warning-alarm-header">
            <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
              <input
                id={"contact-alimentation_warning-" + contact.user_id}
                type="checkbox"
                className="filled-in"
                disabled={!current_user_can_modify || !allowWaterLevelWarning}
                checked={
                  getItemFromName(
                    items,
                    "contact-alimentation_warning-" + contact.user_id
                  ).value > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleGenericCheckboxChange(e, items, setItems)
                }
              />
            </div>
            <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
              <input
                id={"contact-alimentation_alarm-" + contact.user_id}
                type="checkbox"
                className="filled-in"
                disabled={!current_user_can_modify || !allowWaterLevelAlarm}
                checked={
                  getItemFromName(
                    items,
                    "contact-alimentation_alarm-" + contact.user_id
                  ).value > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleGenericCheckboxChange(e, items, setItems)
                }
              />
            </div>
          </div>
          {is_station_pap(station) && (
            <div className="panel-setup-station-line-content-contact-warning-alarm-header">
              <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                <input
                  id={"contact-embacle_warning-" + contact.user_id}
                  type="checkbox"
                  className="filled-in"
                  disabled={
                    !current_user_can_modify || !allowWaterLevelEmbacleWarning
                  }
                  checked={
                    getItemFromName(
                      items,
                      "contact-embacle_warning-" + contact.user_id
                    ).value > 0
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    handleGenericCheckboxChange(e, items, setItems)
                  }
                />
              </div>
              <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                <input
                  id={"contact-embacle_alarm-" + contact.user_id}
                  type="checkbox"
                  className="filled-in"
                  disabled={
                    !current_user_can_modify || !allowWaterLevelEmbacleAlarm
                  }
                  checked={
                    getItemFromName(
                      items,
                      "contact-embacle_alarm-" + contact.user_id
                    ).value > 0
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    handleGenericCheckboxChange(e, items, setItems)
                  }
                />
              </div>
            </div>
          )}
          <div className="panel-setup-station-line-content-contact-warning-alarm-header">
            <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
              <input
                id={"contact-warning-" + contact.user_id}
                type="checkbox"
                className="filled-in"
                disabled={!current_user_can_modify}
                checked={
                  getItemFromName(items, "contact-warning-" + contact.user_id)
                    .value > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleGenericCheckboxChange(e, items, setItems)
                }
              />
            </div>
            <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
              <input
                id={"contact-alarm-" + contact.user_id}
                type="checkbox"
                className="filled-in"
                disabled={!current_user_can_modify}
                checked={
                  getItemFromName(items, "contact-alarm-" + contact.user_id)
                    .value > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleGenericCheckboxChange(e, items, setItems)
                }
              />
            </div>
          </div>
          {pkd_enabled && (
            <div className="panel-setup-station-line-content-contact-warning-alarm-header">
              <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                <input
                  id={"contact-alarm_pkd-" + contact.user_id}
                  type="checkbox"
                  className="filled-in"
                  disabled={!current_user_can_modify || !enablePKD}
                  checked={
                    getItemFromName(
                      items,
                      "contact-alarm_pkd-" + contact.user_id
                    ).value > 0
                      ? enablePKD
                      : false
                  }
                  onChange={(e) =>
                    handleGenericCheckboxChange(e, items, setItems)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  function alarm_contacts_display_allowed_contacts(contacts: Array<Contact>) {
    if (contacts?.length < 1) {
      return;
    }
    if (!current_user_has_technical_access) {
      const contact = contacts.find(
        (contact) =>
          Number(contact.user_id) === Number(AuthenticationService.getUserID())
      );
      if (contact) {
        return display_one_contact(contact);
      }
    } else {
      return contacts?.map((contact) => display_one_contact(contact));
    }
  }

  function display_one_contact_help(contact: Contact) {
    const alimentation_warning =
      getItemFromName(items, "contact-alimentation_warning-" + contact.user_id)
        .value > 0;
    const alimentation_alarm =
      getItemFromName(items, "contact-alimentation_alarm-" + contact.user_id)
        .value > 0;
    const embacle_warning =
      getItemFromName(items, "contact-embacle_warning-" + contact.user_id)
        .value > 0;
    const embacle_alarm =
      getItemFromName(items, "contact-embacle_alarm-" + contact.user_id).value >
      0;
    const contact_warning =
      getItemFromName(items, "contact-warning-" + contact.user_id).value > 0;
    const contact_alarm =
      getItemFromName(items, "contact-alarm-" + contact.user_id).value > 0;
    const contact_alarm_pkd =
      getItemFromName(items, "contact-alarm_pkd-" + contact.user_id).value > 0;
    if (
      !alimentation_warning &&
      !alimentation_alarm &&
      !embacle_warning &&
      !embacle_alarm &&
      !contact_warning &&
      !contact_alarm &&
      !contact_alarm_pkd
    ) {
      return (
        <div className="panel-setup-station-line-content-in-help">
          <strong>{contact.name}</strong> ne recevra <strong>aucune</strong>{" "}
          notification.
        </div>
      );
    }
    if ((
      enablePKD && alimentation_warning &&
      alimentation_alarm &&
      contact_warning &&
      contact_alarm &&
      contact_alarm_pkd
    )||(
      !enablePKD && alimentation_warning &&
      alimentation_alarm &&
      contact_warning &&
      contact_alarm
    )) {
      return (
        <div className="panel-setup-station-line-content-in-help">
          <strong>{contact.name}</strong> recevra <strong>toutes</strong>{" "}
          les notifications.
        </div>
      );
    }
    return (
      <div className="panel-setup-station-line-content-in-help">
        {alimentation_warning ? (
          <div>
            <strong>{contact.name}</strong> recevra une notification lorsque les{" "}
            <strong>niveaux d'eau</strong> déclencheront une alerte{" "}
            <b className="warning-font">Vigilance</b>.
          </div>
        ) : undefined}
        {alimentation_alarm ? (
          <div>
            <strong>{contact.name}</strong> recevra une notification lorsque les{" "}
            <strong>niveaux d'eau</strong> déclencheront une alerte{" "}
            <b className="alarm-font">Urgence</b>.
          </div>
        ) : undefined}
        {contact_warning ? (
          <div>
            <strong>{contact.name}</strong> recevra une notification lorsque la{" "}
            <strong>température</strong> déclenchera une alerte{" "}
            <b className="warning-font">Vigilance</b>.
          </div>
        ) : undefined}
        {contact_alarm ? (
          <div>
            <strong>{contact.name}</strong> recevra une notification lorsque la{" "}
            <strong>température</strong> déclenchera une alerte{" "}
            <b className="alarm-font">Urgence</b>.
          </div>
        ) : undefined}
        {contact_alarm_pkd && enablePKD ? (
          <div>
            <strong>{contact.name}</strong> recevra une alerte{" "}
            <b className="alarm-font">Urgence</b> lorsqu'il y aura suspicion de{" "}
            <strong>PKD</strong>.
          </div>
        ) : undefined}
      </div>
    );
  }

  function alarm_contacts_display_allowed_contacts_help(
    contacts: Array<Contact>
  ) {
    if (contacts?.length < 1) {
      return (
        <div className="panel-setup-station-line-help">
          Aucune notification ne sera envoyée.
        </div>
      );
    }
    if (!current_user_has_technical_access) {
      const contact = contacts.find(
        (contact) =>
          Number(contact.user_id) === Number(AuthenticationService.getUserID())
      );
      if (contact) {
        return (
          <div className="panel-setup-station-line-help">
            {display_one_contact_help(contact)}
          </div>
        );
      } else {
        return (
          <div className="panel-setup-station-line-help">
            Aucune notification ne sera envoyée.
          </div>
        );
      }
    } else {
      return (
        <div className="panel-setup-station-line-help">
          {contacts?.map((contact) => display_one_contact_help(contact))}
        </div>
      );
    }
  }

  function alarm_contacts(contacts: Array<Contact>) {
    return (
      <Fragment>
        <div className="panel-setup-station-line">
          {alarm_display_logo_and_title("contact", return_type_test("contact"))}
          <div className="panel-setup-station-line-content">
            <div className="panel-setup-station-line-content-in">
              <div className="panel-setup-station-line-content-contact-email" />
              {is_station_pap(station) ? (
                <div
                  data-tip={"Alimentation"}
                  className="panel-setup-station-line-content-contact-warning-alarm-header"
                >
                  {return_logo_from_type(
                    "alimentation",
                    "logo-sensor-2-3-size-png"
                  )}
                </div>
              ) : (
                <div
                  data-tip={
                    is_station_papang(station)
                      ? "Niveaux d'eau"
                      : "Niveau d'eau"
                  }
                  className="panel-setup-station-line-content-contact-warning-alarm-header"
                >
                  {return_logo_from_type(
                    "water_level",
                    "logo-sensor-2-3-size-png"
                  )}
                </div>
              )}
              {is_station_pap(station) && (
                <div
                  data-tip={"Embâcles"}
                  className="panel-setup-station-line-content-contact-warning-alarm-header"
                >
                  {return_logo_from_type("embacle", "logo-sensor-2-3-size-png")}
                </div>
              )}
              <div
                data-tip={"Température"}
                className="panel-setup-station-line-content-contact-warning-alarm-header"
              >
                {return_logo_from_type(
                  "temperature",
                  "logo-sensor-2-3-size-png"
                )}
              </div>
              {pkd_enabled && (
                <div
                  data-tip={"Alerte PKD"}
                  className="panel-setup-station-line-content-contact-warning-alarm-header"
                >
                  {return_logo_from_type("pkd", "logo-sensor-2-3-size-png")}
                </div>
              )}
            </div>
            <div className="panel-setup-station-line-content-in">
              <div className="panel-setup-station-line-content-contact-email">
                E-mail :
              </div>
              <div className="panel-setup-station-line-content-contact-warning-alarm-header">
                <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                  <div
                    data-tip={"Niveau de Vigilance"}
                    className={"orangeled led-sensor"}
                  />
                </div>
                <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                  <div
                    data-tip={"Niveau d'Urgence"}
                    className={"redled led-sensor"}
                  />
                </div>
              </div>
              {is_station_pap(station) && (
                <div className="panel-setup-station-line-content-contact-warning-alarm-header">
                  <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                    <div
                      data-tip={"Niveau de Vigilance"}
                      className={"orangeled led-sensor"}
                    />
                  </div>
                  <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                    <div
                      data-tip={"Niveau d'Urgence"}
                      className={"redled led-sensor"}
                    />
                  </div>
                </div>
              )}

              <div className="panel-setup-station-line-content-contact-warning-alarm-header">
                <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                  <div
                    data-tip={"Niveau de Vigilance"}
                    className={"orangeled led-sensor"}
                  />
                </div>
                <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                  <div
                    data-tip={"Niveau d'Urgence"}
                    className={"redled led-sensor"}
                  />
                </div>
              </div>
              {pkd_enabled && (
                <div className="panel-setup-station-line-content-contact-warning-alarm-header">
                  <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                    <div data-tip={"Alerte"} className={"redled led-sensor"} />
                  </div>
                </div>
              )}
            </div>
            {alarm_contacts_display_allowed_contacts(contacts)}
          </div>
        </div>
        {/* Help Waterlevel sensor */}
        {help
          ? alarm_contacts_display_allowed_contacts_help(contacts)
          : undefined}
      </Fragment>
    );
  }

  function alarm_display_logo_and_title(type: string, title: string) {
    return (
      <div className="panel-setup-station-line-logo">
        <div>{title === null ? "" : title}</div>
        <div>{return_logo_from_type(type, "logo-sensor-png")}</div>
      </div>
    );
  }

  function alarm_migration_period() {
    return (
      <div className="panel-setup-station-line">
        {alarm_display_logo_and_title("migration", "Période de migration")}
        <div className="panel-setup-station-line-content">
          <div className="panel-setup-station-line-content-in">
            {isInMigration ? (
              <div>
                <div className="text-warning-font">
                  Période de migration en cours
                </div>
                <div className="text-small-warning-font">
                  Niveau d'eau : L'alerte Vigilance devient immédiate
                </div>
                <div className="text-small-warning-font">
                  Niveau d'eau : L'alerte Urgence se déclenche aux valeurs hors
                  migration de l'alerte de Vigilance
                </div>
              </div>
            ) : (
              <div className="text-warning-font">&nbsp;</div>
            )}
          </div>
          <div className="panel-setup-station-line-content-in">
            <div>Du :</div>
            <input
              id="station-migration_date_start"
              disabled={!current_user_can_modify}
              type="date"
              value={
                getItemFromName(items, "station-migration_date_start").value
              }
              onChange={(e) => handleMigrationDateOnChange(e)}
            />
            <div>Au :</div>
            <input
              id="station-migration_date_end"
              disabled={!current_user_can_modify}
              type="date"
              value={getItemFromName(items, "station-migration_date_end").value}
              onChange={(e) => handleMigrationDateOnChange(e)}
            />
            <div
              data-tip={
                getItemFromName(items, "station-in_migration_forced").value
                  ? "Migration observée forcée"
                  : "Migration observée non forcée"
              }
              className="panel-setup-station-line-content-waterlevel-state"
              onClick={() =>
                current_user_can_modify &&
                toggleItemFromName(
                  items,
                  "station-in_migration_forced",
                  setItems
                )
              }
            >
              {getItemFromName(items, "station-in_migration_forced").value
                ? return_logo_from_type(
                    "migration_select_in_progress",
                    "logo-sensor-half-size-oblon-png"
                  )
                : return_logo_from_type(
                    "migration_select_not_in_progress",
                    "logo-sensor-half-size-oblon-png"
                  )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  //https://codesandbox.io/s/react-dynamic-form-fields-3fjbd?from-embed=&file=/src/index.js
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="panel-setup-station-main">
        {current_user_is_restricted ? (
          <div className="panel-chart-flex-sensor-time">
            <div className="text-warning-font">
              Vous n'avez pas accès à cette section
            </div>
          </div>
        ) : (
          <Fragment>
            {is_station_pap(station) && alarm_migration_period()}
            {is_there_sensor_in_array(general_water_level_sensors) &&
              alarm_water_level_sensor(
                general_water_level_sensors,
                undefined,
                "general",
                is_station_papang(station) ? "Niveaux d'eau" : "Niveau d'eau"
              )}
            {is_there_sensor_in_array(upstream_water_level_sensors) &&
              alarm_water_level_sensor(
                upstream_water_level_sensors,
                undefined,
                "upstream",
                "Alimentation"
              )}
            {is_there_sensor_in_array(upstream_water_level_sensors) &&
              is_there_sensor_in_array(downstream_water_level_sensors) &&
              alarm_water_level_sensor(
                upstream_water_level_sensors,
                downstream_water_level_sensors,
                "downstream",
                "Embâcles"
              )}
            {/* {get_all_typed_analog_sensors_from_station(station, "liquid_level")
              ?.length
              ? alarm_liquid_level_sensor()
              : undefined} */}
            {alarm_liquid_level_sensor(station)}
            {alarm_analog_sensor(station, "temperature")}
            {alarm_analog_sensor(station, "oxymeter")}
            {alarm_analog_sensor(station, "turbidity")}
            {alarm_contacts(station.contacts)}
            <div className="flex-nowrap-justify-between p-2">
              <button
                type="submit"
                className="btn-station-card align-self-center"
                disabled={
                  updating || !current_user_can_modify || !stationFullyLoaded
                }
              >
                {updating || !stationFullyLoaded
                  ? return_logo_from_type("loader", "icon-sensor-30")
                  : "Valider"}
              </button>
              <div className="btn-station-card-spacer" />
              <div onClick={() => setHelp(!help)}>
                {current_user_is_god && help
                  ? return_logo_from_type(
                      "help_on",
                      "logo-oblon-bigger-navbar-png"
                    )
                  : return_logo_from_type(
                      "help_off",
                      "logo-oblon-bigger-navbar-png"
                    )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
      <ReactTooltip type="light" />
    </form>
  );
};

export default StationCardAlarm;
