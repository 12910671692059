import React, { FunctionComponent } from 'react';
import Station from '../models/station';
import {
  fw_nicely_displayed, fw_capteur_show_all,fw_transmitteur_show_all, transmitter_uuid_nicely_displayed, sensor_hw_nicely_displayed, hw_capteur_show_all, transmitter_hw_str, transmitter_get_number_of_quarter_before_next_trame, transmitter_get_battery_strategy_explanation
} from '../helpers/station-helper';
import Transmitter from '../models/transmitter';
import { format_date, add_quarter_to_date } from '../helpers/format-date';
import ReactTooltip from "react-tooltip";
import WaterLevelSensor from '../models/water-level-sensor';
import ModalShowOneSensorTest from './modals/modal-show-one-sensor-tests';

type Props = {
  station: Station
};

const StationCardInformation: FunctionComponent<Props> = ({station}) => {
  //console.log(station);
  function fill_two_lines(the_value:string|string[]|number, title:boolean) {
    return (
        <tr><td colSpan={2} className={(title)?"panel-installation-transmitter-table-head":"panel-installation-transmitter-table-element"}>{the_value?.toString()}</td></tr>
    )
  }
  function one_element(left:string, right:string|string[]|number) {
    return (
      <tr>
        <td className="panel-installation-transmitter-table-head">{left}</td>
        <td className="panel-installation-transmitter-table-element">{right?.toString()}</td>
      </tr>
    )
  }

  function one_wl_line(one:WaterLevelSensor) {
    if(!one){return;}
    return (
      <tr key={"wl-table-"+one.id}>
        <td className="panel-installation-wl-table-element">{one.id}</td>
        <td className="panel-installation-wl-table-element">{one.wl_analog_threshold_on}</td>
        <td className="panel-installation-wl-table-element">{one.wl_analog_threshold_on_samples_quarter_number}</td>
        <td className="panel-installation-wl-table-element"  data-tip={fw_capteur_show_all()}>{(one.fw_version)?fw_nicely_displayed(one.fw_version):""}</td>
        <td className="panel-installation-wl-table-element"  data-tip={hw_capteur_show_all()}>{(one.hw_version)?sensor_hw_nicely_displayed(one.hw_version):""}</td>
        <td className="panel-installation-wl-table-element"><ModalShowOneSensorTest uuid_1={one.uuid_1} uuid_2={one.uuid_2} uuid_3={one.uuid_3}/></td>
      </tr>
    )
  }

  function one_transmitter(one:Transmitter) {
    return (
    <div key={"transmitter-info-"+one.id} className="panel-installation-element">
      <table className="panel-installation-transmitter-table">
        <thead className="panel-installation-wl-table-head">
          <tr>
            <td colSpan={2}>Transmitter</td>
          </tr>
        </thead>
        <tbody className="panel-installation-transmitter-table-line">
          {one_element("transmitter id:",one.id)}
          {one_element("Battery Capacity:",one.battery_capacity+" mAH")}
          {one_element("Connector:",one.connector)}
          {one_element("Comments:",one.comments)}
          {one_element("Installation date:",(one.installation_date)&&format_date(one.installation_date))}
          {one_element("iccid:",(one.iccid))}
          {one_element("imei:",(one.imei))}
          {one_element("data available:",(one.data_available))}
          {one_element("data estimate (days):",(one.data_estimate))}
          {one_element("Last trame(date):",format_date(one.last_trame_received))}
          {one_element(
            "Next trame:",
            format_date(add_quarter_to_date(one.last_trame_received,transmitter_get_number_of_quarter_before_next_trame(one)))+
            transmitter_get_battery_strategy_explanation(one)
          )}
          {one_element("Last reboot(date):",(one.last_reboot)&&format_date(one.last_reboot))}
          {one_element("Last magnet(date):",(one.last_magnet)&&format_date(one.last_magnet))}
          {one_element("Last received version(date):",(one.last_ask_version)&&format_date(one.last_ask_version))}
          {one_element("Last received uuid(date):",(one.last_ask_uuid)&&format_date(one.last_ask_uuid))}
          {one_element("Serial:",one.serial)}
          {one_element("MAC:",one.mac)}
          {one_element("Type:",one.type)}
          {one_element("Operator:",one.last_operator)}
          {one_element("Lqi(RSSI):",one.lqi)}
          {one_element("current modem_type:",one.current_modem_type)}
          {one_element("new modem_type:",one.new_modem_type)}
          {one_element("current sample(nb quarter):",one.current_sample_each_n_quarter)}
          {one_element("current frequency(nb quarter):",one.current_send_each_n_quarter)}
          {one_element("new sample(nb quarter):",one.new_sample_each_n_quarter)}
          {one_element("new frequency(nb quarter):",one.new_send_each_n_quarter)}
          {one_element("current temp_mask:",one.current_temp_mask)}
          {one_element("new temp_mask:",one.new_temp_mask)}
          {one_element("Resend parameters:",one.resend_parameters)}
          <tr>
            <td className="panel-installation-transmitter-table-head">Transmitteur FW v:</td>
            <td className="panel-installation-transmitter-table-element" data-tip={fw_transmitteur_show_all()} >{fw_nicely_displayed(one.fw_version)}</td>
          </tr>
          {one_element("Transmitteur HW v:",transmitter_hw_str(one))}
          {one_element("Modem HW/FW:",one.modem_version)}
          {one_element("Transmitteur UUID v:",transmitter_uuid_nicely_displayed(one))}
          <tr>
            <td className="panel-installation-transmitter-table-head">Defauts Tran id:</td>
            <td className="panel-installation-transmitter-table-element">{one.defauts?.map(def=>def.id+"["+def.trigger_type+"]"+";")}</td>
          </tr>
          {/*one_element("Defauts Analog id:",one.analog_sensors?.map(ana=>ana?.defauts.map(def=>def.id+"["+def.trigger_type+"]").join(";")))*/}
          <tr>
            <td className="panel-installation-transmitter-table-head">Defauts Analog id:</td>
            <td className="panel-installation-transmitter-table-element">{one.analog_sensors?.map(ana=>ana?.defauts?.map(def=>def.id+"["+def.trigger_type+"]"+"("+def.device_id+")"+";"))}</td>
          </tr>
          <tr>
            <td className="panel-installation-transmitter-table-head">Defauts WL id:</td>
            <td className="panel-installation-transmitter-table-element">{one.water_level_sensors?.map(wl=>wl?.defauts?.map(def=>def.id+"["+def.trigger_type+"]"+"("+def.device_id+")"+";"))}</td>
          </tr>
          {one_element("Algo wl high lvl:",(one.auto_high_level_adjustment)?one.auto_high_level_adjustment:0)}
          {one_element("Algo rm within sec:",(one.algo_remove_within_delta_seconds)?one.algo_remove_within_delta_seconds:0)}
          {one_element("monitored:",one.monitored)}
          {one_element("message total:",one.message_data_total)}
          {one_element("message KO:",one.message_data_ko)}
          {one_element("KO ratio(%):",(one.message_data_total)&&(one.message_data_ko)&&Math.ceil((one.message_data_ko*100.0)/one.message_data_total))}
          {fill_two_lines("Analog:", true)}
          {fill_two_lines(one.analog_sensors?.map(ana=>(ana.id+"("+ana.type +")")).join('; '), false)}
          {fill_two_lines("Text:", true)}
          {fill_two_lines(one.text_sensors?.map(one=>(one.id+"("+one.type +")")).join('; '), false)}
          {fill_two_lines("Last trame:", true)}
          {fill_two_lines(one.last_message_received, false)}
        </tbody>
      </table>
      <table className="panel-installation-wl-table">
        <thead className="panel-installation-wl-table-head">
          <tr>
            <td colSpan={6}>WaterLevel</td>
          </tr>
          <tr>
            <td>ID</td>
            <td>AnalogTh</td>
            <td>AnalogTh Nb</td>
            <td>FW</td>
            <td>HW</td>
            <td>UUID</td>
          </tr>
        </thead>
        <tbody className="panel-installation-wl-table-line">
          {one.water_level_sensors?.map(wl=>one_wl_line(wl))}
        </tbody>
      </table>
    </div>

    )
  }

  return (
      <div className="panel-installation-flex">
        {station&&station.transmitters&&station.transmitters.map(one=>(one_transmitter(one)))}
        <ReactTooltip type="light" multiline={true} place="right"/>
      </div>
      );
}

export default StationCardInformation;
