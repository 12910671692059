import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { io } from "socket.io-client";

import {
  createItem,
  getItemFromName,
  Item,
  updateItemFromNameWithValueAndSetter,
} from "../../helpers/items-helper";
import { authHeader } from "../../helpers/auth-headers";
import { handleGenericChange } from "../../helpers/handler-helper";
import { WebsocketEmitRequest } from "../../models/websocket";
import SiteService, { getSocketIOParams } from "../../services/site-service";
import Company from "../../models/company";
import Client from "../../models/client";

const ModalCreateModifyClient: FunctionComponent = () => {
  const displayName = "ModalCreateModifyClient:";
  const enableDebug = false;
  const enableMoreDebug = false;
  const enableDebugWebsocket = false;

  const [selectedClientId, setSelectedClientId] = useState<number>(0);
  const [websocketEmit, setWebsocketEmit] = useState<
    WebsocketEmitRequest | undefined
  >(undefined);
  const [items, setItems] = useState<Item[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [showModal, setshowModal] = useState(false);
  const [allowValidate, setAllowValidate] = useState(false);

  useEffect(() => {
    enableDebugWebsocket && console.log(displayName, "useEffect:", "WEBSOCKET");
    /* BEGINNING websocket connection */
    if (showModal) {
      const socket = io(SiteService.getWebsocketUrl(), getSocketIOParams());
      /* END websocket connection */
      /* BEGINNING listenner setup */
      socket.on("done", (values_desc_str: string) => {
        enableDebugWebsocket &&
          console.log(displayName, "got done:", values_desc_str);
        setshowModal(false);
        setItems([]);
        setSelectedClientId(0);
      });
      socket.on("clients_desc", (the_desc_str: string) => {
        const the_clients = JSON.parse(the_desc_str);
        enableDebug &&
          console.log(displayName, "Clients length:", the_clients.length);
        if (enableMoreDebug) console.log(displayName, "Clients:", the_clients);
        setClients(the_clients);
      });
      socket.on("companies_desc", (the_desc_str: string) => {
        const the_companies = JSON.parse(the_desc_str);
        enableDebug &&
          console.log(displayName, "Companies length:", the_companies.length);
        if (enableMoreDebug)
          console.log(displayName, "Companies:", the_companies);
        setCompanies(the_companies);
      });
      socket.on("logged_out", (logged_out_desc_str: string) => {
        console.log(displayName, "logged_out:", logged_out_desc_str);
        setItems([]);
        setSelectedClientId(0);
      });
      /* END listenner setup */
      /* START websocket emit handler */
      if (websocketEmit) {
        if (enableDebugWebsocket)
          console.log(
            displayName,
            "----------> websocket emit :",
            websocketEmit
          );
        socket.emit(websocketEmit.channel, websocketEmit.request);
      }
      /* END websocket emit handler */
      /* START websocket cleanup handler */
      return function cleanup() {
        enableDebug && console.log(displayName, "useEffect.cleanup");
        socket.disconnect();
      };
      /* END websocket cleanup handler */
    }
  }, [websocketEmit, showModal]);

  useEffect(() => {
    if (!showModal && items.length) {
      setItems([]);
      setSelectedClientId(0);
    }
    if (showModal) {
      enableDebug && console.log(displayName, "Retrieve all existing clients");
      const the_request: any = {
        type: "clients_desc",
        from: "token",
        liste: [],
        token: authHeader(),
      };
      setWebsocketEmit({ channel: "request", request: the_request });
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal && !items.length) {
      enableDebug && console.log(displayName, "Set First Item");
      let newItemArray: Array<Item> = [];
      newItemArray.push(createItem("selected-client", 0));
      newItemArray.push(createItem("name", "Obligatoire"));
      newItemArray.push(createItem("nickname", ""));
      newItemArray.push(createItem("address", ""));
      newItemArray.push(createItem("postal_city", ""));
      newItemArray.push(createItem("phone", ""));
      newItemArray.push(createItem("email", ""));
      setItems([...items, ...newItemArray]);
    }
  }, [showModal, items]);

  useEffect(() => {
    if (!selectedClientId) {
      setCompanies([]);
    }
  }, [selectedClientId]);

  useEffect(() => {
    if (showModal) {
      enableDebug &&
        console.log(displayName, "selectedClientId:", selectedClientId);
      const theClient = clients?.find((one) => one.id === selectedClientId);
      if (theClient) {
        enableDebug && console.log(displayName, "theClient:", theClient);
        updateItemFromNameWithValueAndSetter(
          items,
          "name",
          theClient.name,
          setItems
        );
        updateItemFromNameWithValueAndSetter(
          items,
          "nickname",
          theClient.nickname,
          setItems
        );
        updateItemFromNameWithValueAndSetter(
          items,
          "address",
          theClient.address,
          setItems
        );
        updateItemFromNameWithValueAndSetter(
          items,
          "postal_city",
          theClient.postal_city,
          setItems
        );
        updateItemFromNameWithValueAndSetter(
          items,
          "phone",
          theClient.phone,
          setItems
        );
        updateItemFromNameWithValueAndSetter(
          items,
          "email",
          theClient.email,
          setItems
        );
      } else {
        updateItemFromNameWithValueAndSetter(items, "name", "", setItems);
        updateItemFromNameWithValueAndSetter(items, "nickname", "", setItems);
        updateItemFromNameWithValueAndSetter(items, "address", "", setItems);
        updateItemFromNameWithValueAndSetter(
          items,
          "postal_city",
          "",
          setItems
        );
        updateItemFromNameWithValueAndSetter(items, "phone", "", setItems);
        updateItemFromNameWithValueAndSetter(items, "email", "", setItems);
      }
      if (selectedClientId) {
        enableDebug &&
          console.log(displayName, "Retrieve all companies for this client");
        const the_request: any = {
          type: "companies_desc",
          from: "client_id",
          liste: [selectedClientId],
          token: authHeader(),
        };
        setWebsocketEmit({ channel: "request", request: the_request });
      }
    }
  }, [showModal, selectedClientId]);

  useEffect(() => {
    if (!selectedClientId && getItemFromName(items, "name").value) {
      setAllowValidate(true);
    } else if (selectedClientId) {
      setAllowValidate(true);
    } else {
      setAllowValidate(false);
    }
  }, [showModal, selectedClientId, items]);

  function the_modal() {
    const handleDelete = () => {
      enableDebug && console.log(displayName, "handleDelete !!!");
      if (window.confirm("Etes vous sur de vouloir supprimer ce client ?")) {
        if (selectedClientId) {
          /* Remove existing client */
          const the_request: any = {
            type: "commands",
            liste: [
              JSON.stringify({
                command: "delete_client",
                id: selectedClientId,
              }),
            ],
            from: "token",
            token: authHeader(),
          };
          setWebsocketEmit({ channel: "request", request: the_request });
        }
      }
    };
    const handleSubmit = () => {
      enableDebug && console.log(displayName, "handleSubmit !!!");
      if (selectedClientId) {
        /* Modify existing client */
        const the_request: any = {
          type: "commands",
          liste: [
            JSON.stringify({
              command: "update_client",
              id: selectedClientId,
              name: getItemFromName(items, "name").value,
              nickname: getItemFromName(items, "nickname").value,
              address: getItemFromName(items, "address").value,
              postal_city: getItemFromName(items, "postal_city").value,
              phone: getItemFromName(items, "phone").value,
              email: getItemFromName(items, "email").value,
            }),
          ],
          from: "token",
          token: authHeader(),
        };
        setWebsocketEmit({ channel: "request", request: the_request });
      } else {
        /* Create new client */
        const the_request: any = {
          type: "commands",
          liste: [
            JSON.stringify({
              command: "create_client",
              name: getItemFromName(items, "name").value,
              nickname: getItemFromName(items, "nickname").value,
              address: getItemFromName(items, "address").value,
              postal_city: getItemFromName(items, "postal_city").value,
              phone: getItemFromName(items, "phone").value,
              email: getItemFromName(items, "email").value,
            }),
          ],
          from: "token",
          token: authHeader(),
        };
        setWebsocketEmit({ channel: "request", request: the_request });
      }
    };

    return (
      <div className="modal-main">
        <div className="modal-container">
          {/* Start Header */}
          <div className="modal-header">Créer/Modifier un client</div>
          {/* End Header */}
          {/* Start Body */}
          <div className="modal-body m-w-500">
            <div className="flex-nowrap-justify-around">
              <select
                key="selected-client"
                id="selected-client"
                className="squama-select"
                value={selectedClientId}
                onChange={(e) => setSelectedClientId(Number(e.target.value))}
              >
                <option value="0">NouveauClient</option>
                {clients?.map((client) => {
                  return (
                    <option value={client.id} key={client.id}>
                      {client.nickname ? client.nickname : client.name}
                    </option>
                  );
                })}
              </select>
              {selectedClientId ? (
                <button
                  type="button"
                  className="squama-btn-navbar squama-btn-gestion-delete squama-btn-gestion-modal"
                  onClick={() => handleDelete()}
                >
                  Supprimer
                </button>
              ) : undefined}
            </div>
            <hr className="modal-separator"/>
            <div className="form-floating">
              <input
                type="text"
                id="name"
                className="form-control"
                value={getItemFromName(items, "name").value}
                onChange={(e) =>
                  handleGenericChange(
                    e,
                    items,
                    setItems,
                    enableDebug,
                    displayName
                  )
                }
              />
              <label className="">Nom:</label>
            </div>
            <div className="form-floating">
              <input
                type="text"
                id="nickname"
                className="form-control"
                value={getItemFromName(items, "nickname").value}
                onChange={(e) =>
                  handleGenericChange(
                    e,
                    items,
                    setItems,
                    enableDebug,
                    displayName
                  )
                }
              />
              <label className="">Surnom:</label>
            </div>
            <div className="form-floating">
              <input
                type="text"
                id="address"
                className="form-control"
                value={getItemFromName(items, "address").value}
                onChange={(e) =>
                  handleGenericChange(
                    e,
                    items,
                    setItems,
                    enableDebug,
                    displayName
                  )
                }
              />
              <label className="">Adresse:</label>
            </div>
            <div className="form-floating">
              <input
                type="text"
                id="postal_city"
                className="form-control"
                value={getItemFromName(items, "postal_city").value}
                onChange={(e) =>
                  handleGenericChange(
                    e,
                    items,
                    setItems,
                    enableDebug,
                    displayName
                  )
                }
              />
              <label className="">Code Postale Ville:</label>
            </div>
            <div className="form-floating">
              <input
                type="text"
                id="phone"
                className="form-control"
                value={getItemFromName(items, "phone").value}
                onChange={(e) =>
                  handleGenericChange(
                    e,
                    items,
                    setItems,
                    enableDebug,
                    displayName
                  )
                }
              />
              <label className="">Téléphone:</label>
            </div>
            <div className="form-floating">
              <input
                type="email"
                id="email"
                className="form-control"
                value={getItemFromName(items, "email").value}
                onChange={(e) =>
                  handleGenericChange(
                    e,
                    items,
                    setItems,
                    enableDebug,
                    displayName
                  )
                }
              />
              <label className="">Email:</label>
            </div>
            {selectedClientId ? (
              <Fragment>
                <hr className="modal-separator"/>
                <div className="row">
                  <div className="col">
                    Companies Associées :
                  </div>
                  <ul className="col list-disc">
                    {companies &&
                      companies.map((company) => {
                        return (
                          <li value={company.id} key={"company-" + company.id}>
                            {company.name}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </Fragment>
            ) : undefined}
          </div>
          {/* End Body */}
          {/* Start Footer */}
          <div className="modal-footer">
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              onClick={() => setshowModal(false)}
            >
              Annuler
            </button>
            <button
              type="submit"
              className={
                "squama-btn-navbar squama-btn-gestion-modal squama-btn-gestion"
              }
              disabled={!allowValidate}
              onClick={() => handleSubmit()}
            >
              {selectedClientId ? "Modifier" : "Ajouter"}
            </button>
          </div>
          {/* End Footer */}
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      {showModal && the_modal()}
      <button
        type="button"
        className="squama-btn-navbar squama-btn-gestion"
        onClick={() => setshowModal(true)}
      >
        Édition Client
      </button>
    </Fragment>
  );
};

export default ModalCreateModifyClient;
