import React, { createContext, useContext, useEffect, useState } from "react";
import { io ,Socket } from "socket.io-client";
import SiteService, { getSocketIOParams } from "./site-service";

// Type pour le contexte WebSocket
interface WebSocketContextType {
  socket: Socket | null;
}

// Création du contexte WebSocket
const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

// Hook pour utiliser le contexte WebSocket
export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket doit être utilisé à l'intérieur de WebSocketProvider");
  }
  return context;
};

// Fournisseur du contexte WebSocket
export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const displayName = "WebSocketProvider:";
  const enableDebug = false;
  const enableMoreDebug = false;

  const debug = (...args: any[]) => {
    if (enableDebug) console.debug(displayName, ...args);
  };
  const debugMore = (...args: any[]) => {
    if (enableMoreDebug) console.debug(displayName, ...args);
  };
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = io(SiteService.getWebsocketUrl(), getSocketIOParams());
    if (enableDebug || enableMoreDebug){
      newSocket.onAny((eventName, datas) => {
        debug("WS(",eventName,")");
        debugMore("WS(",eventName,"):",datas);
      });
    }
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};
