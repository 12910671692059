import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Station from "../models/station";
import {
  return_logo_from_type,
  station_get_min_temperature,
  station_get_max_temperature,
  station_get_mean_temperature,
  station_get_current_temperature,
  station_get_general_status,
  station_get_temperature_status,
  station_get_waterlevel_status,
} from "../helpers/station-helper";
import TextTable from "../components/TextTable";
import ReactTooltip from "react-tooltip";
import {
  compare_value_for_ordering,
  numberForcedOneDecimal,
} from "../helpers/tools";
import AuthenticationService from "../services/authentication-service";
import { useWebSocket } from "../services/websocket-service";
import WebsocketSquamaRequest, { WebsocketEmitRequest } from "../models/websocket";
import { authHeader } from "../helpers/auth-headers";

type Props = {
  stations: Array<Station>;
};

const SquamaCompanyListBoard: FunctionComponent<Props> = ({ stations }) => {
  const displayName = "SquamaCompanyListBoard:";
  const enableDebug = false;
  const enableDebugWebsocket = false;
  const debug = (...args: any[]) => {
    if (enableDebug) console.debug(displayName, ...args);
  };
  const debugWS = (...args: any[]) => {
    if (enableDebugWebsocket) console.debug(displayName, ...args);
  };
  const { socket } = useWebSocket();
  const [websocketEmit, setWebsocketEmit] = useState<
    WebsocketEmitRequest | undefined
  >(undefined);
  const [displayAllClientStations, setDisplayAllClientStations] =
    useState<boolean>(false);
  const userIsAdmin = AuthenticationService.getUserHaveTechnicalAccessToOneOfThisStations(stations);

  useEffect(() => {
    if (!socket) {
      debug("No WS available yet in main useEffect");
      return;
    }
    if (websocketEmit) {
      debugWS("----------> websocket :", websocketEmit.request.type);
      socket.emit(websocketEmit.channel, websocketEmit.request);
    }
  }, [socket, websocketEmit]);

  useEffect(() => {
    if (
      displayAllClientStations &&
      stations.length > 0 &&
      stations[0].client_id
    ) {
      const the_request = new WebsocketSquamaRequest(
        "stations_desc",
        authHeader()
      );
      the_request.number = 1;
      the_request.liste = [stations[0].client_id];
      the_request.from = "client_id";
      setWebsocketEmit({ channel: "request", request: the_request });
    }
  }, [displayAllClientStations]);

  function display_station_current_temperature(station: Station): string {
    const value = station_get_current_temperature(station);
    if (value) {
      return String(numberForcedOneDecimal(value)) + "°C";
    }
    return "Inconnue";
  }

  function display_station_min_max_temperature(station: Station): string {
    const valueMin = station_get_min_temperature(station);
    const valueMax = station_get_max_temperature(station);
    if (valueMin && valueMax) {
      return (
        String(numberForcedOneDecimal(valueMin)) +
        "°C - " +
        String(numberForcedOneDecimal(valueMax)) +
        "°C"
      );
    }
    return "Inconnue";
  }

  function display_station_mean_temperature(station: Station): string {
    const value = station_get_mean_temperature(station);
    if (value) {
      return String(numberForcedOneDecimal(value)) + "°C";
    }
    return "Inconnue";
  }

  function compare_station(
    stationARow: any,
    stationBRow: any,
    _columnId: any
  ): number {
    debug("Comparing :", _columnId);
    const stateOrder = ["green", "orange", "red", "grey"];
    if (_columnId === "t_mean") {
      const valueA = station_get_mean_temperature(stationARow.original);
      const valueB = station_get_mean_temperature(stationBRow.original);
      return compare_value_for_ordering(valueA, valueB);
    } else if (_columnId === "t_min_max") {
      const valueA = station_get_min_temperature(stationARow.original);
      const valueB = station_get_min_temperature(stationBRow.original);
      return compare_value_for_ordering(valueA, valueB);
    } else if (_columnId === "t_current") {
      const valueA = station_get_current_temperature(stationARow.original);
      const valueB = station_get_current_temperature(stationBRow.original);
      return compare_value_for_ordering(valueA, valueB);
    } else if (_columnId === "state") {
      return (
        stateOrder.indexOf(station_get_general_status(stationARow.original)) -
        stateOrder.indexOf(station_get_general_status(stationBRow.original))
      );
    } else if (_columnId === "t_state") {
      return (
        stateOrder.indexOf(
          station_get_temperature_status(stationARow.original)
        ) -
        stateOrder.indexOf(station_get_temperature_status(stationBRow.original))
      );
    } else if (_columnId === "wl_state") {
      return (
        stateOrder.indexOf(
          station_get_waterlevel_status(stationARow.original)
        ) -
        stateOrder.indexOf(station_get_waterlevel_status(stationBRow.original))
      );
    } else if (_columnId === "name") {
      if (stationARow.original?.name && stationBRow.original?.name) {
        const stringA: string = stationARow.original?.name;
        const stringB: string = stationBRow.original?.name;
        return stringA.localeCompare(stringB);
      }
    }
    return 0;
  }

  function get_station_id_name_dict(station: Station) {
    if (station) {
      return { id: station.id, name: station.name };
    }
    return { id: 0, name: "" };
  }
  const light_column_size = 120;
  const TextValueColumns = [
    {
      id: "type",
      header: "Type",
      accessorKey: "type",
      cell: (info: any) => (
        <div className="flex items-center" data-tip={info.getValue()}>
          {return_logo_from_type(info.getValue(), "logo-sensor-half-size-png")}
        </div>
      ),
      size: 80,
      //size: 200, //starting column size
      //minSize: 50, //enforced during column resizing
      //maxSize: 500, //enforced during column resizing
    },
    {
      id: "state",
      header: "Etat de la station",
      accessorFn: station_get_general_status,
      cell: (info: any) => (
        <div className={info.getValue() + "led led-sensor-2x"}></div>
      ),
      size: light_column_size,
      sortingFn: compare_station,
    },
    {
      id: "name",
      header: "Nom de la station",
      accessorFn: get_station_id_name_dict,
      cell: (info: any) => (
        <Link
          className=" flex text-left"
          to={"/stations/" + info.getValue().id + "/setup"}
        >
          {info.getValue().name}
        </Link>
      ),
      minSize: 100,
      maxSize: 500,
      sortingFn: compare_station,
    },
    {
      id: "t_current",
      header: "T°C mesurée instantanée",
      accessorFn: display_station_current_temperature,
      size: light_column_size,
      sortingFn: compare_station,
    },
    {
      id: "t_min_max",
      header: "T°C min-max journalière",
      accessorFn: display_station_min_max_temperature,
      size: light_column_size,
      sortingFn: compare_station,
    },
    {
      id: "t_mean",
      header: "T°C moyenne journalière",
      accessorFn: display_station_mean_temperature,
      size: light_column_size,
      sortingFn: compare_station,
    },
    {
      id: "t_state",
      header: "État capteur température",
      accessorFn: station_get_temperature_status,
      cell: (info: any) => (
        <div className={info.getValue() + "led led-sensor-2x"}></div>
      ),
      size: light_column_size,
    },
    {
      id: "wl_state",
      header: "État capteur eau",
      accessorFn: station_get_waterlevel_status,
      cell: (info: any) => (
        <div className={info.getValue() + "led led-sensor-2x"}></div>
      ),
      size: 95,
    },
  ];

  return (
    <div className="main-center-div the-background">
      <div className="flex-station-cards-column">
        <div className="panel-station-default">
          <div className="modal-header flex-center px-2">AFFICHAGE LISTE</div>
          {userIsAdmin ? (
            <div className="row flex-nowrap-justify-evenly pb-3">
              <div className="col-6 panel-station-map-select-part-text">
                Afficher toutes les stations
              </div>
              <div className="col-6">
                <input
                  id={"all-companies"}
                  data-tip={"Afficher toutes les stations connues"}
                  type="checkbox"
                  className="panel-station-map-selector-checkbox stay-in-div"
                  checked={displayAllClientStations}
                  disabled={displayAllClientStations}
                  onChange={(e) =>
                    setDisplayAllClientStations(!displayAllClientStations)
                  }
                />
              </div>
            </div>
          ) : undefined}
          <TextTable
            orderBy="state"
            displayFooter={false}
            orderByDesc={true}
            allowPagination={true}
            numberOfElementDisplayed={1000}
            searchedText={["id", "mac"]}
            allValues={stations}
            valuesColumns={TextValueColumns}
            classNameHeader="company-list-table-text-thead"
            classNameTH="company-list-table-text-td-th"
            classNameTD="company-list-table-text-td-th"
          />
          <div className="panel-station-footer"></div>
        </div>
      </div>
      <ReactTooltip type="light" />
    </div>
  );
};
export default SquamaCompanyListBoard;
