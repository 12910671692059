import { FunctionComponent, useState, useEffect, Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useWebSocket } from "../services/websocket-service";
import AuthenticationService from "../services/authentication-service";
import WebsocketSquamaRequest, {
  WebsocketEmitRequest,
} from "../models/websocket";
import { authHeader } from "../helpers/auth-headers";
import Client from "../models/client";
import Company from "../models/company";
import Station from "../models/station";
import SquamaCompanyDashboard from "./squama-company-dashboard";
import PageNotFound from "./page-not-found";
import SquamaCompanyListBoard from "./squama-company-list";
import SquamaCompanyMapBoard from "./squama-company-mapboard";
import SquamaSingleStation from "./squama-single-station";
import ModalCompanySettings from "../components/modals/modal-company-settings";
import SquamaNavbar from "../components/squama-navbar";
import LoaderLogo from "../img/loader-661x661.gif";
import { areArraysEqual } from "../helpers/tools";
import { get_array_of_stations_id } from "../helpers/station-helper";
import ReactTooltip from "react-tooltip";

const SquamaDashBoard: FunctionComponent = () => {
  const displayName = "SquamaDashBoard:";
  const enableDebug = false;
  const enableMoreDebug = false;
  const enableDebugWebsocket = false;

  const debug = (...args: any[]) => {
    if (enableDebug) console.debug(displayName, ...args);
  };
  const debugMore = (...args: any[]) => {
    if (enableMoreDebug) console.debug(displayName, ...args);
  };
  const debugWS = (...args: any[]) => {
    if (enableDebugWebsocket) console.debug(displayName, ...args);
  };
  const { socket } = useWebSocket();
  const [websocketEmit, setWebsocketEmit] = useState<
    WebsocketEmitRequest | undefined
  >(undefined);
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<number>(0);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [stationsPartReceived, setStationsPartReceived] = useState<Station[]>(
    []
  );
  const [stations, setStationsFullyReceived] = useState<Station[]>([]);
  const [stationsId, setStationsId] = useState<number[]>([]);
  const [reload, setReload] = useState(false);
  const [canShowLogout, setCanShowLogout] = useState(false);

  const current_user_is_god = AuthenticationService.getUserIsGod();

  const params = useParams<{
    id: string;
    basepath: string;
    finalpath: string;
  }>();
  const history = useNavigate();

  useEffect(() => {
    //TODO:const timer = setTimeout(() => setCanShowLogout(true), 5000);
    /* BEGINNING websocket connection */
    if (!socket){
      debug("No WS available yet in main useEffect");
      return;
    }
    /* END websocket connection */
    /* BEGINNING listenner setup */
    socket.on("companies_id", (the_str_received: string) => {
      debugWS("<---------- companies_id");
      const result = JSON.parse(the_str_received);
      debugMore("companies_id:", result);
      if (result?.length > 0) {
        console.log("Reroute to 1st company id:", result[0]);
        history("/companies/" + result[0]);
      }
    });
    socket.on("station_id", (the_str_received: string) => {
      debugWS("<---------- station_id");
      const result: Array<number> = JSON.parse(the_str_received);
      //TODO:setCanShowLogout(false);
      setStationsId(result);
      debugMore("station_id:", result);
      const the_request = new WebsocketSquamaRequest(
        "stations_desc",
        authHeader()
      );
      the_request.filter = ALL_FILTERS[0];
      the_request.number = result.length;
      the_request.liste = result;
      the_request.from = "station_id";
      the_request.answer_method = ["one_time"];
      setWebsocketEmit({ channel: "request", request: the_request });
    });
    socket.on("clients_id", (the_str_received: string) => {
      debugWS("<---------- clients_id");
      const result = JSON.parse(the_str_received);
      debugMore("clients_id:", result);
      if (result?.length > 0) {
        setSelectedClientId(result[0]);
      }
    });
    socket.on("clients_desc", (the_str_received: string) => {
      debugWS("<---------- clients_desc");
      debugMore(
        "clients:Just received answer Json.length:",
        JSON.parse(the_str_received).length
      );
      setClients(JSON.parse(the_str_received));
    });
    socket.on("companies_desc", (the_companies_desc_str: string) => {
      debugWS("<---------- companies_desc");
      const the_companies = JSON.parse(the_companies_desc_str);
      debugMore("Companies:", the_companies);
      setCompanies(the_companies);
      if (!params.id) {
        setSelectedCompanyId(the_companies[0].id);
      }
    });
    socket.on("stations_desc", (the_stations_desc_str: string) => {
      debugWS("<---------- stations_desc");
      const the_stations = JSON.parse(the_stations_desc_str);
      debug("Stations length:", the_stations.length);
      debugMore("Station:", the_stations);
      setStationsPartReceived(JSON.parse(the_stations_desc_str));
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName, "logged_out:", logged_out_desc_str);
      //TODO:setCanShowLogout(true);
      setStationsPartReceived([]);
      setStationsFullyReceived([]);
    });
    /* END listenner setup */
    /* START websocket emitter handler */
    if (websocketEmit) {
      debugWS("----------> websocket :", websocketEmit.request.type);
      socket.emit(websocketEmit.channel, websocketEmit.request);
    }
    /* END websocket emitter handler */
    /* START websocket cleanup handler */
    return function cleanup() {
      debugWS("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      debugWS("! useEffect websocket.cleanup !");
      debugWS("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      //TODO:clearTimeout(timer);
    };
    /* END websocket cleanup handler */
  }, [socket, history, params.id, websocketEmit]);

  //Make sure to empty stations when selectedClientId change
  useEffect(() => {
    if(selectedClientId){
      setSelectedCompanyId(0);
      setCompanies([]);
      setStationsFullyReceived([]);
      setStationsPartReceived([]);
    }
  }, [selectedClientId]);


  /* BEGINNING OF REQUEST PROCESS */

  /*
   * How requests work :
   * From station_id array, we request in multiples steps stations_desc with filter_line
   * filter_line request are made incrementatly from ALL_FILTERS
   * Process : we analyze all stations_desc received, once all received and all with the same filter_line
   * we switch to another stations_desc with nex filter_line
   */

  const ALL_FILTERS = [
    //["name"],
    [
      "name",
      "analog_sensors",
      "water_level_sensors",
      "type_temperature",
      "type_liquid_level",
      "last_update",
    ],
    //["name", "general_status", "analog_sensors", "water_level_sensors", "type_temperature"],
    [], //Meaning all
  ];

  useEffect(() => {
    debug("useEffect request");
    if (!socket){
      debug("No WS available yet in useEffect request");
      return;
    }
    //0. no params.id Guess first companies for this client and move to /companies/X
    if (!params || !Number(params.id)) {
      debug("No params probably at /dashboard or /companies/0");
      const the_request = new WebsocketSquamaRequest(
        "companies_id",
        authHeader()
      );
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    //1.setSelectedCompanyId from params.id (very beginning)
    else if (params.id && !selectedCompanyId) {
      debug("Start setSelectedCompanyId to", Number(params.id));
      setSelectedCompanyId(Number(params.id));
    }
    //2.We need to know what is the client_id associated with selectedCompanyId and setSelectedClientId
    if (!selectedClientId && selectedCompanyId) {
      debug(
        "Request clients_id associated to company_id(",
        selectedCompanyId,
        ")"
      );
      const the_request = new WebsocketSquamaRequest(
        "clients_id",
        authHeader()
      );
      the_request.from = "company_id";
      the_request.liste = [selectedCompanyId];
      the_request.number = 1;
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    //3.Request clients details based on auth (only for god)
    else if (selectedClientId && !clients.length) {
      debug("Request all clients_desc");
      const the_request = new WebsocketSquamaRequest(
        "clients_desc",
        authHeader()
      );
      if (current_user_is_god) {
        the_request.filter = "add_first_company_id";
      }
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    //4.(for god we already have clients) Requests all companies associated to the selectedClientId
    else if (clients.length && !companies.length) {
      debug("Request all companies_desc");
      const the_request = new WebsocketSquamaRequest(
        "companies_desc",
        authHeader()
      );
      if (current_user_is_god) {
        the_request.from = "client_id";
        the_request.number = 1;
        the_request.liste = [selectedClientId];
      }
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    //5.Request stations_id of selectedCompanyId
    else if (selectedCompanyId && companies.length) {
      debug("Request all stations_id");
      const the_request = new WebsocketSquamaRequest(
        "station_id",
        authHeader()
      );
      the_request.filter = "stations_ordered";
      the_request.number = 1;
      the_request.liste = [selectedCompanyId];
      the_request.from = "company_id";
      setStationsPartReceived([]);
      setStationsFullyReceived([]);
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    if (reload) {
      setReload(false);
      setCompanies([]);
      setStationsPartReceived([]);
      setStationsFullyReceived([]);
    }
  }, [
    socket,
    params.id,
    companies.length,
    clients.length,
    selectedClientId,
    selectedCompanyId,
    current_user_is_god,
    reload,
  ]);

  function getNextFilter(currentFilter: string[]): string[] {
    // Validate inputs
    if (!Array.isArray(currentFilter) || currentFilter.length === 0) {
      console.error("Invalid current filter: must be a non-empty array.");
      return [];
    }
    if (!Array.isArray(ALL_FILTERS) || ALL_FILTERS.length === 0) {
      console.error("ALL_FILTERS must be a non-empty array of filters.");
      return [];
    }
    // Find the index of the current filter in ALL_FILTERS
    const currentIndex = ALL_FILTERS.findIndex((filter) =>
      areArraysEqual(currentFilter, filter)
    );
    // If the current filter is not found, return the last filter
    if (currentIndex === -1) {
      console.warn(
        "Current filter not found in ALL_FILTERS. Returning the last filter."
      );
      return ALL_FILTERS[ALL_FILTERS.length - 1];
    }
    // Return the next filter, or the last filter if already at the end
    return currentIndex < ALL_FILTERS.length - 1
      ? ALL_FILTERS[currentIndex + 1]
      : ALL_FILTERS[ALL_FILTERS.length - 1];
  }

  function validateAndFindCommonFilter(stations: Station[]): string[] {
    debug("validateAndFindCommonFilter: Start");
    debug("stations.length:", stations.length, stations);

    // Return early if stations array is invalid or empty
    if (!Array.isArray(stations) || stations.length === 0) {
      debug("No stations provided or stations array is empty");
      return [];
    }

    // Initialize the common filter with `null` to track the first station's filter
    let commonFilter: string[] | null = null;

    for (const station of stations) {
      // Validate the station's filter
      if (
        !station.filter ||
        !Array.isArray(station.filter) ||
        station.filter.length === 0
      ) {
        debug("Station has no valid filter:", station);
        return [];
      }

      // If this is the first station, set its filter as the common filter
      if (commonFilter === null) {
        commonFilter = station.filter;
        continue;
      }

      // Compare current station's filter with the common filter
      //if (JSON.stringify(commonFilter) !== JSON.stringify(station.filter)) {
      if (!areArraysEqual(commonFilter, station.filter)) {
        debug("Station has a different filter:", station);
        return [];
      }
    }
    debug(
      "validateAndFindCommonFilter: All stations have same filter",
      commonFilter
    );
    return commonFilter || [];
  }

  function fillNewFromOldStations(
    oldStations: Station[],
    newStations: Station[]
  ): Station[] {
    // Validate entries
    if (!Array.isArray(oldStations) || !Array.isArray(newStations)) {
      console.error("Both oldStations and newStations must be arrays");
      return newStations;
    }
    // Get all IDs from newStations
    const existingStationIds = new Set(
      newStations.map((station) => station.id)
    );
    // Add missing IDs in newStations from oldStations
    oldStations.forEach((oldStation) => {
      if (!existingStationIds.has(oldStation.id)) {
        newStations.push(oldStation);
        existingStationIds.add(oldStation.id); // Mise à jour du Set
      }
    });
    return newStations;
  }

  useEffect(() => {
    /* Fired each time we receive something into stationsPartReceived */
    debugMore("useEffect triggered by stationsPartReceived");
    debugMore("stationsPartReceived:", stationsPartReceived);
    debugMore("stationsPartReceived.length:", stationsPartReceived.length);
    debugMore("stations:", stations);
    debugMore("stations.length:", stations.length);
    if (stationsPartReceived.length) {
      const newStationsDisplayed = fillNewFromOldStations(
        stations,
        stationsPartReceived
      );
      setStationsFullyReceived(newStationsDisplayed);
      const filter_line = validateAndFindCommonFilter(newStationsDisplayed);
      if (filter_line.length) {
        //We can ask next filter steps
        const next_filter_line = getNextFilter(filter_line);
        if (!areArraysEqual(next_filter_line, filter_line)) {
          debug("new request with new filter:", next_filter_line);
          const the_request = new WebsocketSquamaRequest(
            "stations_desc",
            authHeader()
          );
          the_request.number = newStationsDisplayed.length;
          the_request.liste = get_array_of_stations_id(newStationsDisplayed);
          the_request.from = "station_id";
          the_request.filter = next_filter_line;
          setWebsocketEmit({ channel: "request", request: the_request });
        }
      }
    }
    debugMore("end stationsPartReceived:", stationsPartReceived);
    debugMore("end stations:", stations);
  }, [stationsPartReceived]);

  useEffect(() => {
    debugMore("params.id:", params.id);
    debugMore("params.basepath:", params.basepath);
    debugMore("params.finalpath:", params.finalpath);
    debugMore("current_user_is_god:", current_user_is_god);
  }, []);

  /* END OF REQUEST PROCESS */

  function displayCorrectPage() {
    if (params?.basepath === "companies" && params?.finalpath === "list") {
      return <SquamaCompanyListBoard stations={stations} />;
    } else if (
      params?.basepath === "companies" &&
      params?.finalpath === "map"
    ) {
      return <SquamaCompanyMapBoard stations={stations} />;
    } else if (params?.basepath === "stations") {
      return <SquamaSingleStation />;
    } else if (
      ["dashboard", "companies"].includes(params?.basepath || "") &&
      !params?.finalpath
    ) {
      return (
        <SquamaCompanyDashboard
          stations={stations}
          finalPath={params?.finalpath}
          stationsNumber={stationsId.length}
        />
      );
    }
    return <PageNotFound />;
  }

  const clientSelectHandleChange = (event: any) => {
    debug("Selected Client id:", event.target.value);
    const the_client = clients?.find(
      (one) => one.id === Number(event.target.value)
    );
    if (the_client) {
      debug("Selected Client:", the_client);
      if (params?.finalpath) {
        history(
          "/companies/" + the_client.first_company_id + "/" + params.finalpath
        );
      } else {
        history("/companies/" + the_client.first_company_id);
      }
    }
  };
  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      {current_user_is_god && clients?.length > 1 ? (
        <Fragment>
          <hr />
          <div className="row-navbar">
            <select
              key={"select-clients"}
              className="squama-select"
              value={selectedClientId}
              onChange={(e) => clientSelectHandleChange(e)}
            >
              {clients.map((client) => {
                return (
                  <option key={"select-" + client.id} value={client.id}>
                    {client.nickname ? client.nickname : client.name}
                  </option>
                );
              })}
            </select>
          </div>
        </Fragment>
      ):undefined}
      {companies?.length > 1 || clients?.length > 1 ? <hr /> : undefined}
      {/* Loader part */}
      <div className="row-navbar">
        {companies ? undefined : (
          <div>
            <img className="loader-logo" src={LoaderLogo} alt="" />
          </div>
        )}
        {(companies?.length > 1 || clients?.length > 1) &&
          companies.map((company) => (
            <div
              key={"div-" + company.id}
              className="squama-item-navbar squama-item-navbar-companies"
            >
              {company.id === selectedCompanyId ? (
                current_user_is_god ? (
                  <div
                    key={"button-" + company.id}
                    className="squama-btn-navbar-companies btn-selected flex-nowrap-justify-between px-2"
                  >
                    <div />
                    <div className="text-clipped">{company.name}</div>
                    <ModalCompanySettings
                      company={company}
                      stations={stations}
                      setWebsocketEmit={setWebsocketEmit}
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    key={"button-" + company.id}
                    className="squama-btn-navbar-companies btn-selected"
                    disabled={selectedCompanyId === 0}
                  >
                    {company.name}
                  </button>
                )
              ) : (
                <div>
                  <Link
                    to={
                      params?.finalpath
                        ? "/companies/" + company.id + "/" + params.finalpath
                        : "/companies/" + company.id
                    }
                  >
                    <button
                      type="button"
                      key={"button-" + company.id}
                      className={
                        company.id === selectedCompanyId
                          ? "squama-btn-navbar-companies btn-selected"
                          : "squama-btn-navbar-companies"
                      }
                      disabled={selectedCompanyId === 0}
                    >
                      {company.name}
                    </button>
                  </Link>
                </div>
              )}
            </div>
          ))}
      </div>
      <hr />

      {canShowLogout ? (
        <div className="main-center-column-div">
          <div className="flex-center white-font h1-font">
            Merci pour votre visite
          </div>
          <Link to="/accueil">
            <button type="button" className="squama-btn-navbar">
              Accueil
            </button>
          </Link>
        </div>
      ) : (
        displayCorrectPage()
      )}
      <ReactTooltip type="light" />
    </div>
  );
};
export default SquamaDashBoard;
