export function areArraysEqual<T>(array1: T[], array2: T[]): boolean {
  // Check if both arrays are null or undefined
  if (array1 === array2) return true;
  // Check if either array is null/undefined or lengths are different
  if (!array1 || !array2 || array1.length !== array2.length) return false;
  // Compare each element in the arrays
  for (let i = 0; i < array1.length; i++) {
    const value1 = array1[i];
    const value2 = array2[i];
    // Handle nested arrays (deep equality)
    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (!areArraysEqual(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false; // Elements are not equal
    }
  }
  return true; // Arrays are equal
}

export function replace_spaces_with_underscore(entry: string): string {
  return entry.replace(/ /g, "_");
}

export function replace_tild_with_underscore(entry: string): string {
  return entry.replace(/'/g, "_");
}

export function remove_quotes(entry: string): string {
  return entry.replace(/"/g, "");
}

export function replace_specific_char(entry: string): string {
  return remove_quotes(
    replace_spaces_with_underscore(replace_tild_with_underscore(entry))
  );
}

export function get_date_as_string(): string {
  var rightNow = new Date();
  return rightNow.toISOString().slice(0, 10).replace(/-/g, "");
}

export function debugFunctionIdValue(
  componentName: string,
  functionName: string,
  id: any,
  value: any
) {
  console.log(componentName, functionName, '=>id:"', id, '" = "', value, '"');
}

export function numberForcedOneDecimal(the_input: number): string {
  return Number(the_input).toFixed(1);
}

export function convertIntoType(theValue: any, theValueType: string = "") {
  if (!theValue || theValue.toString() === "") {
    return theValue;
  }
  if (theValueType === "string") {
    return theValue.toString();
  }
  if (theValueType === "number") {
    return Number(theValue);
  }
  return theValue;
}

export function compare_value_for_ordering(
  valueA: number | null,
  valueB: number | null
): number {
  //console.log("compare_value_for_ordering(=>",valueA,",=>",valueB,"id:");
  if (!valueA) {
    return -1;
  }
  if (!valueB) {
    return 1;
  }
  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0;
}

export function return_text_true_if_a_equal_b_else_text_false(
  a: string,
  b: string,
  text_true: string,
  text_false: string
): string {
  if (a === b) {
    return text_true;
  }
  return text_false;
}

export function isBtnSelected(current: string | undefined, trigger: string) {
  if (!current) {
    return "";
  }
  return return_text_true_if_a_equal_b_else_text_false(
    current,
    trigger,
    "btn-selected",
    ""
  );
}

export function updateOtherDateWithStartEndDateAndModif(
  startDateValue: string,
  endDateValue: string,
  modif: string
): string {
  if (modif.includes("start")) {
    if (endDateValue === "") {
      return startDateValue;
    }
    const p_start_date = new Date(startDateValue);
    const p_end_date = new Date(endDateValue);
    if (p_start_date > p_end_date) {
      //"Cannot chose a starting date after ending date =>Return startDateValue to update endDateValue"
      return startDateValue;
    }
    return endDateValue;
  } else if (modif.includes("end")) {
    if (startDateValue === "") {
      return startDateValue;
    }
    const p_start_date = new Date(startDateValue);
    const p_end_date = new Date(endDateValue);
    if (p_start_date > p_end_date) {
      //"Cannot chose an ending date before starting date =>Return endDateValue to update startDateValue"
      console.log("Cannot chose a starting date after ending date !");
      return endDateValue;
    }
    return startDateValue;
  }
  return endDateValue;
}
