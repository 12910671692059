import { FunctionComponent, useState, useEffect, Fragment } from "react";
import Station from "../models/station";
import MyMap from "../components/my-map";
import StationCard from "../components/station-card";
import { return_logo_from_type } from "../helpers/station-helper";
import AuthenticationService from "../services/authentication-service";
import { useWebSocket } from "../services/websocket-service";
import WebsocketSquamaRequest, {
  WebsocketEmitRequest,
} from "../models/websocket";
import { authHeader } from "../helpers/auth-headers";

type Props = {
  stations: Array<Station>;
};

const SquamaCompanyMapBoard: FunctionComponent<Props> = ({ stations }) => {
  const displayName = "SquamaCompanyMapBoard:";
  const enableDebug = false;
  const enableDebugWebsocket = false;
  const debug = (...args: any[]) => {
    if (enableDebug) console.debug(displayName, ...args);
  };
  const debugWS = (...args: any[]) => {
    if (enableDebugWebsocket) console.debug(displayName, ...args);
  };
  const { socket } = useWebSocket();
  const [websocketEmit, setWebsocketEmit] = useState<
    WebsocketEmitRequest | undefined
  >(undefined);
  const [filteredStations, setFilteredStations] = useState<Station[]>([]);
  const [selectedStation, setSelectedStation] = useState<Station | null>(null);
  const [displayAllClientStations, setDisplayAllClientStations] =
    useState<boolean>(false);

  const [displayThermeau, setDisplayThermeau] = useState<boolean>(true);
  const [displayFlow, setDisplayFlow] = useState<boolean>(true);
  const [displayPapeye, setDisplayPapeye] = useState<boolean>(true);
  const [displayVigeel, setDisplayVigeel] = useState<boolean>(true);
  const [displayFlhauteur, setDisplayFlhauteur] = useState<boolean>(true);

  const [displayOk, setDisplayOk] = useState<boolean>(true);
  const [displayVigilance, setDisplayVigilance] = useState<boolean>(true);
  const [displayUrgence, setDisplayUrgence] = useState<boolean>(true);
  const [displayKo, setDisplayKo] = useState<boolean>(true);

  const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > 1450);
  const [currentUserIsRestricted, setCurrentUserIsRestricted] =
    useState<boolean>(false);
  const userIsAdmin = AuthenticationService.getUserHaveTechnicalAccessToOneOfThisStations(stations);

  const updateMedia = () => {
    debug("updateMedia");
    setDesktop(window.innerWidth > 1450);
  };

  useEffect(() => {
    if (!socket) {
      debug("No WS available yet in main useEffect");
      return;
    }
    if (websocketEmit) {
      debugWS("----------> websocket :", websocketEmit.request.type);
      socket.emit(websocketEmit.channel, websocketEmit.request);
    }
  }, [socket, websocketEmit]);

  useEffect(() => {
    if (
      displayAllClientStations &&
      stations.length > 0 &&
      stations[0].client_id
    ) {
      const the_request = new WebsocketSquamaRequest(
        "stations_desc",
        authHeader()
      );
      the_request.number = 1;
      the_request.liste = [stations[0].client_id];
      the_request.from = "client_id";
      setWebsocketEmit({ channel: "request", request: the_request });
    }
  }, [displayAllClientStations]);

  useEffect(() => {
    debug("useEffect:", "FIRST_TIME start");
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    debug("useEffect:", "FILTERED_STATIONS");
    if (filteredStations && filteredStations.length) {
      if (selectedStation) {
        const test = filteredStations.filter(
          (one_station) => one_station.id === selectedStation.id
        );
        if (!test.length) {
          setSelectedStation(filteredStations[0]);
        }
      } else {
        setSelectedStation(filteredStations[0]);
      }
    } else {
      setSelectedStation(null);
    }
  }, [filteredStations]);

  useEffect(() => {
    debug("useEffect:", "FILTER_COMPONENT start");
    if (stations && stations.length) {
      setFilteredStations(
        stations.filter((one_station) => {
          if (!displayThermeau && one_station.type === "thermeau") {
            return false;
          }
          if (!displayFlow && one_station.type === "flow") {
            return false;
          }
          if (!displayPapeye && one_station.type === "pap") {
            return false;
          }
          if (!displayVigeel && one_station.type === "papang") {
            return false;
          }
          if (!displayFlhauteur && one_station.type === "flhauteur") {
            return false;
          }
          if (
            !displayOk &&
            one_station.general_status === "green" &&
            one_station.least_days_since_last_trame_received <= 1
          ) {
            return false;
          }
          if (
            !displayVigilance &&
            one_station.general_status === "orange" &&
            one_station.least_days_since_last_trame_received <= 1
          ) {
            return false;
          }
          if (
            !displayUrgence &&
            one_station.general_status === "red" &&
            one_station.least_days_since_last_trame_received <= 1
          ) {
            return false;
          }
          if (
            !displayKo &&
            one_station.least_days_since_last_trame_received > 1
          ) {
            return false;
          }
          return true;
        })
      );
    } else {
      setFilteredStations([]);
    }
    debug("useEffect:", "FILTER_COMPONENT end");
  }, [
    stations,
    displayThermeau,
    displayFlow,
    displayPapeye,
    displayVigeel,
    displayOk,
    displayVigilance,
    displayUrgence,
    displayKo,
  ]);

  useEffect(() => {
    debug("useEffect:", "Check restricted user at least for one station");
    if (stations?.length) {
      const current_user_is_restricted = stations.find((station) =>
        AuthenticationService.getUserIsRestrictedToStation(station)
      );
      //if restricted for one station => restricted for all
      if (current_user_is_restricted) {
        setCurrentUserIsRestricted(true);
      }
    }
  }, [stations]);

  function display_panel_smaller_part(
    title: string,
    base_logo_name: string,
    isSelectedState: any,
    setSelectedState: any
  ) {
    return (
      <div className="panel-station-map-select-part-part-element">
        <button
          key={"bp-" + title}
          className="panel-chart-flex-sensor-type-each btn-sensor-type"
          onClick={() =>
            isSelectedState ? setSelectedState(false) : setSelectedState(true)
          }
        >
          {return_logo_from_type(
            base_logo_name + (isSelectedState ? "-selected" : ""),
            "logo-sensor-png"
          )}
        </button>
        <div className="panel-station-map-select-part-title">{title}</div>
      </div>
    );
  }

  function display_panel_selector() {
    return (
      <div className="panel-station-default panel-station-smaller panel-station-large-enough">
        <div className="panel-station-heading panel-station-assec-heading">
          <div className="station_title_bar station_title_text">
            &nbsp;&nbsp;&nbsp;&nbsp;AFFICHAGE CARTE
          </div>
        </div>
        <div className="panel-station-body panel-station-simple-row-justify-center panel-station-map-select-main">
          <div className="panel-station-map-select-part">
            <div className="panel-station-map-selector-part">
              {userIsAdmin && (
                <Fragment>
                  <div className="panel-station-map-select-part-text">
                    Afficher toutes les stations
                  </div>
                  <input
                    id={"all-companies"}
                    data-tip={"Afficher toutes les stations connues"}
                    type="checkbox"
                    className="panel-station-map-selector-checkbox stay-in-div"
                    checked={displayAllClientStations}
                    disabled={displayAllClientStations}
                    onChange={(e) =>
                      setDisplayAllClientStations(!displayAllClientStations)
                    }
                  />
                </Fragment>
              )}
            </div>
          </div>
          <div className="panel-station-map-select-part">
            <div className="panel-station-map-select-part-text">
              Affichage Stations
            </div>
            <div className="panel-station-map-select-part-part">
              {display_panel_smaller_part(
                "THERM'EAU",
                "thermeau",
                displayThermeau,
                setDisplayThermeau
              )}
              {display_panel_smaller_part(
                "F'LOW",
                "flow",
                displayFlow,
                (value: any) => {
                  setDisplayFlow(value);
                  setDisplayFlhauteur(value);
                }
              )}
              {display_panel_smaller_part(
                "PAP'EYE",
                "pap",
                displayPapeye,
                setDisplayPapeye
              )}
              {display_panel_smaller_part(
                "VIG'EEL",
                "vigeel",
                displayVigeel,
                setDisplayVigeel
              )}
              {/* display_panel_smaller_part(
                  "FL'HAUTEUR",
                  "flhauteur",
                  displayFlhauteur,
                  setDisplayFlhauteur
                ) */}
            </div>
          </div>
          <div className="panel-station-map-select-part">
            <div className="panel-station-map-select-part-text">
              Affichage Alertes
            </div>
            <div className="panel-station-map-select-part-part">
              {display_panel_smaller_part(
                "OK",
                "round_ok",
                displayOk,
                setDisplayOk
              )}
              {display_panel_smaller_part(
                "VIGICLANCE",
                "round_warning",
                displayVigilance,
                setDisplayVigilance
              )}
              {display_panel_smaller_part(
                "URGENCE",
                "round_alarm",
                displayUrgence,
                setDisplayUrgence
              )}
              {display_panel_smaller_part(
                "ABSENCE D'EMISSION",
                "round_ko",
                displayKo,
                setDisplayKo
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  debug("---- CYCLE ----");
  debug("isDesktop:", isDesktop);
  debug("stations:", stations);
  debug("filteredStations.length:", filteredStations.length);
  debug("selectedStation:", selectedStation);

  if (isDesktop) {
    return (
      <div className="main-center-div the-background">
        <div className="flex-station-cards-column">
          {display_panel_selector()}
          {selectedStation ? (
            <StationCard
              key={selectedStation.id}
              station={selectedStation}
              fullscreen={false}
              mapMode={true}
            />
          ) : undefined}
        </div>
        {
          <MyMap
            auto_zoom={true}
            zoom={10}
            stations={filteredStations}
            setSelectedStation={setSelectedStation}
            showPopup={false}
            allow_zoom={!currentUserIsRestricted}
          />
        }
      </div>
    );
  }
  return (
    <div className="main-center-div the-background">
      <div className="flex-station-cards-column">
        {display_panel_selector()}
        {
          <MyMap
            auto_zoom={true}
            zoom={10}
            stations={filteredStations}
            setSelectedStation={setSelectedStation}
            showPopup={false}
            allow_zoom={!currentUserIsRestricted}
          />
        }
        {selectedStation ? (
          <StationCard
            key={selectedStation.id}
            station={selectedStation}
            fullscreen={false}
            mapMode={true}
          />
        ) : undefined}
      </div>
    </div>
  );
};
export default SquamaCompanyMapBoard;
