import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SquamaNavbar from '../components/squama-navbar';
import MyMap from '../components/my-map';
import Station from '../models/station';
import { io } from "socket.io-client";
import { authHeader } from '../helpers/auth-headers';
import SiteService, { getSocketIOParams } from '../services/site-service';
import TextTable from '../components/TextTable';
import { format_date } from '../helpers/format-date';
import AuthenticationService from '../services/authentication-service';
import WebsocketSquamaRequest from '../models/websocket';

const SquamaGestion: FunctionComponent = () => {
  const displayName = "ModalShowSensorsTextTable:";
  const enableDebug = false;
  const enableCycleDebug = false;
  const enableDebugWebsocket = false;
  const enableMoreDebug = false;
  const history = useNavigate();
  const [stations, setStations] = useState<Station[]>([]);

  if (AuthenticationService.getUserID()===undefined) {
    history('/accueil');
  }
  useEffect(() => {
    if (enableDebug) console.log(displayName, "useEffect");
    /* BEGINNING websocket connection */
    const socket = io(SiteService.getWebsocketUrl(), getSocketIOParams());
    /* END websocket connection */
    /* BEGINNING listenner setup */
    socket.on("station_id", (the_str_received: string) => {
      enableDebugWebsocket &&
        console.log(displayName, "<---------- station_id");
      const result: Array<number> = JSON.parse(the_str_received);
      enableMoreDebug && console.log(displayName, "station_id:", result);
      //current_user_is_god && setUpdating(result.length);
      const the_request = new WebsocketSquamaRequest(
        "stations_desc",
        authHeader()
      );
      the_request.number = result.length;
      the_request.liste = result;
      the_request.from = "station_id";
      the_request.filter = ["allow_nanogis", "installation_date", "transmitters", "gps", "status"];
      socket.emit("request", the_request);
    });
    socket.on("stations_desc", (the_stations_desc_str: string) => {
      const the_stations = JSON.parse(the_stations_desc_str);
      if (enableMoreDebug) console.log(displayName, "Station:", the_stations);
      setStations(the_stations);
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName, "logged_out:", logged_out_desc_str);
      //MAYBE reroute to accueil
      history('/accueil');
      setStations([]);
    });
    /* END listenner setup */
    /* START websocket requests */
    if (enableDebug) console.log(displayName, "Request all companies_desc");

    const the_request: any = {
      type: "station_id",
      number: 0,
      liste: [0],
      filter: ["allow_nanogis", "with GPS", "without SQUAMA"],
      from: "company_id",
      token: authHeader()
    }
    socket.emit("request", the_request);
    /* END websocket requests */
    /* START websocket cleanup handler */
    return function cleanup() {
      if (enableDebug) console.log(displayName, "useEffect.cleanup")
      socket.disconnect();
    };
    /* END websocket cleanup handler */
  }, [])

  const TextValueColumns =
    [
      {
        header: "Nom de station",
        accessorKey: "name",
        size: 250,
      },
      {
        header: "Client",
        accessorKey: "company_name",
        size: 250,
      },
      {
        header: "Date Installation",
        accessorKey: "installation_date",
        size: 145,
        cell: (info: any) => format_date(info.getValue()),
      }
    ]
  if (enableCycleDebug) {
    console.log(displayName, "---- CYCLE ----");
    console.log(displayName, "stations:", stations);
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-visualize-stations flex-station-cards the-background">
        {/* <div className="main-visualize-stations-element white-background"> */}
        <div className='flex-station-cards-column'>
          <div className="panel-station-default">
            <div className="panel-station-heading panel-station-thermeau-heading">
              <div className='station_title_bar station_title_text items-center'>
                Dernières stations installées
              </div>
            </div>
            {
              (stations) && (stations.length > 0) && (
                <TextTable
                  orderBy='installation_date'
                  orderByDesc={true}
                  allowPagination={true}
                  numberOfElementDisplayed={40}
                  searchedText={["id", "mac"]}
                  allValues={stations}
                  valuesColumns={TextValueColumns}
                />
              )
            }
            <div className="panel-station-footer"></div>
          </div>
        </div>
        <div className="main-visualize-stations-element">
          <MyMap auto_zoom={true} stations={stations} display_marker_color={false} />
        </div>
      </div>
    </div >
  );
}

export default SquamaGestion;