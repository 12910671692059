import { FunctionComponent, useEffect, useState } from "react";
import Station from "../models/station";
import Transmitter from "../models/transmitter";
import { get_array_of_transmitters_id } from "../helpers/station-helper";
import { format_date } from "../helpers/format-date";
import ReactTooltip from "react-tooltip";
import { useWebSocket } from "../services/websocket-service";
import { WebsocketEmitRequest } from "../models/websocket";
import { authHeader } from "../helpers/auth-headers";

type Props = {
  station: Station;
};

const StationCardDiagnostics: FunctionComponent<Props> = ({ station }) => {
  const displayName = "StationCardDiagnostics:";
  const enableDebug = false;
  const enableDebugWebsocket = false;

  const debug = (...args: any[]) => {
    if (enableDebug) console.debug(displayName, ...args);
  };
  const debugWS = (...args: any[]) => {
    if (enableDebugWebsocket) console.debug(displayName, ...args);
  };

  const [transmitters, setTransmitters] = useState<Transmitter[]>([]);
  const [websocketEmit, setWebsocketEmit] = useState<
    WebsocketEmitRequest | undefined
  >(undefined);
  const { socket } = useWebSocket();

  useEffect(() => {
    debug("useEffect start");
    if (!socket) {
      debug("No WS available yet in main useEffect");
      return;
    }
    socket.on("logged_out", (logged_out_desc_str: string) => {
      enableDebug &&
        console.log(displayName, "logged_out:", logged_out_desc_str);
    });
    socket.on("transmitters_desc", (the_transmitters_desc_str: string) => {
      debugWS("<---------- transmitters_desc");
      const the_transmitters = JSON.parse(the_transmitters_desc_str);
      enableDebug &&
        the_transmitters &&
        console.log(displayName, "Transmitters:", the_transmitters);
      the_transmitters &&
        the_transmitters.length > 0 &&
        setTransmitters(the_transmitters);
    });
    if (websocketEmit) {
      debugWS("----------> websocket :", websocketEmit.request.type);
      socket.emit(websocketEmit.channel, websocketEmit.request);
    }
    debug("useEffect end");
    return function cleanup() {
      debug("useEffect.cleanup");
    };
  }, [socket, websocketEmit]);

  useEffect(() => {
    if (
      (!transmitters || transmitters.length <= 0) &&
      station &&
      station.transmitters &&
      station.transmitters.length
    ) {
      const the_request: any = {
        type: "transmitters_desc",
        number: station.transmitters.length,
        liste: get_array_of_transmitters_id(station),
        from: "transmitter_id",
        filter: ["diagnostics"],
        token: authHeader(),
      };
      setWebsocketEmit({ channel: "request", request: the_request });
    } else {
      //enableDebug && console.log()
    }
  }, [transmitters]);

  //console.log(station);
  function fill_two_lines(
    the_value: string | string[] | number,
    title: boolean,
    class_name_add: string = ""
  ) {
    return (
      <tr>
        <td
          colSpan={2}
          className={
            (title
              ? "panel-installation-transmitter-table-head "
              : "panel-installation-transmitter-table-element ") +
            class_name_add
          }
        >
          {the_value?.toString()}
        </td>
      </tr>
    );
  }
  function one_element(
    left: string,
    right: string | string[] | number,
    left_class: string = "",
    right_class: string = "",
    data_tip_string: string = ""
  ) {
    return (
      <tr data-tip={data_tip_string}>
        <td
          className={"panel-installation-transmitter-table-head " + left_class}
        >
          {left}
        </td>
        <td
          className={
            "panel-installation-transmitter-table-element " + right_class
          }
        >
          {right?.toString()}
        </td>
      </tr>
    );
  }
  function get_class_name_from_string_value_equal_waited_value(
    value: string,
    waited_value: string
  ) {
    if (value === waited_value) {
      return "status-ok black-font";
    }
    return "status-ko black-font";
  }
  function get_class_name_from_number_value_equal_waited_value(
    value: number,
    waited_value: number
  ) {
    if (value === waited_value) {
      return "status-ok black-font";
    }
    return "status-ko black-font";
  }
  function get_class_name_from_value_is_arround_min_max_value(
    value: number,
    min_value: number,
    max_value: number
  ) {
    if (min_value < value && value < max_value) {
      return "status-ok black-font";
    }
    return "status-ko black-font";
  }
  function get_class_name_from_value_is_under_max_value(
    value: number,
    max_value: number
  ) {
    if (value < max_value) {
      return "status-ok black-font";
    }
    return "status-ko black-font";
  }
  function get_class_name_from_value(
    value: number,
    ok_value: number,
    yl_value: number,
    wn_value: number
  ) {
    if (value > ok_value) {
      return "status-ok black-font";
    } else if (value > yl_value) {
      return "status-yl";
    } else if (value > wn_value) {
      return "status-wn";
    }
    return "status-ko black-font";
  }

  function one_transmitter(one: Transmitter) {
    return (
      <div className="panel-installation-element">
        <table className="panel-installation-transmitter-table">
          <thead className="panel-installation-wl-table-head">
            <tr>
              <td colSpan={2}>Transmitter</td>
            </tr>
          </thead>
          <tbody className="panel-installation-transmitter-table-line">
            {one_element("transmitter id:", one.id)}
            {one_element("MAC:", one.mac)}
            {one_element("Comments:", one.comments)}
            {one_element("Created:", one.created && format_date(one.created))}
            {one_element(
              "current Sample(nb quarter):",
              one.current_sample_each_n_quarter
            )}
            {one_element(
              "current Sent frequency(nb quarter):",
              one.current_send_each_n_quarter
            )}
            {one_element(
              "Battery Capacity:",
              one.battery_capacity + " mAH",
              "",
              get_class_name_from_number_value_equal_waited_value(
                one.battery_capacity,
                5200
              )
            )}
            {one_element(
              "Connection type:",
              one.type,
              "",
              get_class_name_from_string_value_equal_waited_value(
                one.type,
                "LTE-M"
              )
            )}
            {one_element(
              "Operator:",
              one.last_operator,
              "",
              get_class_name_from_string_value_equal_waited_value(
                one.last_operator,
                "Orange F"
              )
            )}
            {fill_two_lines(
              "Charging/Discharging stats",
              true,
              "background-dark-grey"
            )}
            {one_element(
              "Charging stat [9H;17H] day:",
              one.battery_charging_percent_stat_day + " %"
            )}
            {one_element(
              "Discharging stat [9H;17H] day:",
              one.battery_discharging_percent_stat_day + " %"
            )}
            {one_element(
              "Charging-Discharging stat [9H;17H] day:",
              one.battery_charging_percent_stat_day +
                one.battery_discharging_percent_stat_day +
                " %",
              "",
              get_class_name_from_value_is_under_max_value(
                -1 *
                  (one.battery_charging_percent_stat_day +
                    one.battery_discharging_percent_stat_day),
                1
              )
            )}
            {one_element(
              "Charging stat [9H;17H] week:",
              one.battery_charging_percent_stat_week + " %"
            )}
            {one_element(
              "Discharging stat [9H;17H] week:",
              one.battery_discharging_percent_stat_week + " %"
            )}
            {one_element(
              "Charging-Discharging stat [9H;17H] week:",
              one.battery_charging_percent_stat_week +
                one.battery_discharging_percent_stat_week +
                " %",
              "",
              get_class_name_from_value_is_under_max_value(
                -1 *
                  (one.battery_charging_percent_stat_week +
                    one.battery_discharging_percent_stat_week),
                1
              )
            )}
            {one_element(
              "Charging stat [9H;17H] month:",
              one.battery_charging_percent_stat_month + " %"
            )}
            {one_element(
              "Discharging stat [9H;17H] month:",
              one.battery_discharging_percent_stat_month + " %"
            )}
            {one_element(
              "Charging-Discharging stat [9H;17H] month:",
              one.battery_charging_percent_stat_month +
                one.battery_discharging_percent_stat_month +
                " %",
              "",
              get_class_name_from_value_is_under_max_value(
                -1 *
                  (one.battery_charging_percent_stat_month +
                    one.battery_discharging_percent_stat_month),
                1
              )
            )}
            {one_element(
              "Charging stat [9H;17H] 6 months:",
              one.battery_charging_percent_stat_6months + " %"
            )}
            {one_element(
              "Discharging stat [9H;17H] 6 months:",
              one.battery_discharging_percent_stat_6months + " %"
            )}
            {one_element(
              "Charging-Discharging stat [9H;17H] 6 months:",
              one.battery_charging_percent_stat_6months +
                one.battery_discharging_percent_stat_6months +
                " %",
              "",
              get_class_name_from_value_is_under_max_value(
                -1 *
                  (one.battery_charging_percent_stat_6months +
                    one.battery_discharging_percent_stat_6months),
                1
              )
            )}
            {fill_two_lines("Charging values", true, "background-dark-grey")}
            {one_element(
              "Charging stat MIN [9H;17H] day:",
              one.battery_charging_min_stat_day + " mV"
            )}
            {one_element(
              "Charging stat MIN TIME [9H;17H] day:",
              one.battery_charging_min_time_stat_day &&
                format_date(one.battery_charging_min_time_stat_day)
            )}
            {one_element(
              "Charging stat AVG [9H;17H] day:",
              one.battery_charging_avg_stat_day + " mV",
              "",
              get_class_name_from_value(
                one.battery_charging_avg_stat_day,
                3600,
                3400,
                3300
              )
            )}
            {one_element(
              "Charging stat MAX [9H;17H] day:",
              one.battery_charging_max_stat_day + " mV",
              "",
              get_class_name_from_value(
                one.battery_charging_max_stat_day,
                4000,
                3600,
                3300
              )
            )}
            {one_element(
              "Charging stat MAX TIME [9H;17H] day:",
              one.battery_charging_max_time_stat_day &&
                format_date(one.battery_charging_max_time_stat_day)
            )}
            {one_element(
              "Charging stat MIN [9H;17H] week:",
              one.battery_charging_min_stat_week + " mV"
            )}
            {one_element(
              "Charging stat AVG [9H;17H] week:",
              one.battery_charging_avg_stat_week + " mV"
            )}
            {one_element(
              "Charging stat MAX [9H;17H] week:",
              one.battery_charging_max_stat_week + " mV"
            )}
            {one_element(
              "Charging stat MIN [9H;17H] month:",
              one.battery_charging_min_stat_month + " mV"
            )}
            {one_element(
              "Charging stat AVG [9H;17H] month:",
              one.battery_charging_avg_stat_month + " mV"
            )}
            {one_element(
              "Charging stat MAX [9H;17H] month:",
              one.battery_charging_max_stat_month + " mV"
            )}
            {one_element(
              "Charging stat AVG [9H;17H] 6 months:",
              one.battery_charging_avg_stat_6months + " mV"
            )}
            {fill_two_lines("Send/Receive Stats", true, "background-dark-grey")}
            {one_element(
              "Last day sent nb:",
              one.last_day_sent + "/" + 96 / one.current_send_each_n_quarter,
              "",
              get_class_name_from_value_is_arround_min_max_value(
                one.last_day_sent,
                96 / one.current_send_each_n_quarter - 3,
                96 / one.current_send_each_n_quarter + 3
              )
            )}
            {one_element(
              "Last day samples nb:(sent by number of " +
                one.current_send_each_n_quarter /
                  one.current_sample_each_n_quarter +
                ")",
              one.last_day_samples +
                "/" +
                96 / one.current_sample_each_n_quarter,
              "",
              get_class_name_from_value_is_arround_min_max_value(
                one.last_day_samples,
                96 / one.current_sample_each_n_quarter -
                  one.current_send_each_n_quarter /
                    one.current_sample_each_n_quarter -
                  2,
                96 / one.current_sample_each_n_quarter + 2
              )
            )}
            {one_element(
              "Last day samples avg:",
              one.last_day_samples_avg_delta +
                "/" +
                one.current_sample_each_n_quarter * 900 +
                "sec",
              "",
              get_class_name_from_value_is_under_max_value(
                Math.abs(
                  one.current_sample_each_n_quarter * 900 -
                    one.last_day_samples_avg_delta
                ),
                one.current_sample_each_n_quarter * 45
              ) //5%
            )}
            {fill_two_lines("Last day bad samples:", true)}
            {fill_two_lines(one.last_day_samples_bad_delta + " [sec]", false)}
            {fill_two_lines("Last day wrong samples datetime:", true)}
            {fill_two_lines(
              one.last_day_samples_bad_delta_full_explaination,
              false
            )}
            {/*
          {one_element("","")}
          {one_element("Installation date:",(one.installation_date)&&format_date(one.installation_date))}
          {one_element("data available:",(one.data_available))}
          {one_element("data estimate (days):",(one.data_estimate))}
          {one_element("Last reboot(date):",(one.last_reboot)&&format_date(one.last_reboot))}
          {one_element("Last magnet(date):",(one.last_magnet)&&format_date(one.last_magnet))}
          {one_element("Last received version(date):",(one.last_ask_version)&&format_date(one.last_ask_version))}
          {one_element("Last received uuid(date):",(one.last_ask_uuid)&&format_date(one.last_ask_uuid))}
          {one_element("Operator:",one.last_operator)}
          {one_element("Lqi(RSSI):",one.lqi)}
          {one_element("current modem_type:",one.current_modem_type)}
          {one_element("new modem_type:",one.new_modem_type)}
          {one_element("new sample(nb quarter):",one.new_sample_each_n_quarter)}
          {one_element("new frequency(nb quarter):",one.new_send_each_n_quarter)}
          {one_element("current temp_mask:",one.current_temp_mask)}
          {one_element("new temp_mask:",one.new_temp_mask)}
          {one_element("Resend parameters:",one.resend_parameters)}
          <tr>
            <td className="panel-installation-transmitter-table-head">Transmitteur FW v:</td>
            <td className="panel-installation-transmitter-table-element" data-tip={fw_transmitteur_show_all()} >{fw_nicely_displayed(one.fw_version)}</td>
          </tr>
          {one_element("Transmitteur HW v:",transmitter_hw_str(one))}
          {one_element("Transmitteur UUID v:",transmitter_uuid_nicely_displayed(one))}
          <tr>
            <td className="panel-installation-transmitter-table-head">Defauts Tran id:</td>
            <td className="panel-installation-transmitter-table-element">{one.defauts?.map(def=>def.id+"["+def.trigger_type+"]"+";")}</td>
          </tr>
          <tr>
            <td className="panel-installation-transmitter-table-head">Defauts Analog id:</td>
            <td className="panel-installation-transmitter-table-element">{one.analog_sensors?.map(ana=>ana?.defauts?.map(def=>def.id+"["+def.trigger_type+"]"+"("+def.device_id+")"+";"))}</td>
          </tr>
          <tr>
            <td className="panel-installation-transmitter-table-head">Defauts WL id:</td>
            <td className="panel-installation-transmitter-table-element">{one.water_level_sensors?.map(wl=>wl?.defauts?.map(def=>def.id+"["+def.trigger_type+"]"+"("+def.device_id+")"+";"))}</td>
          </tr>
          {one_element("Algo wl high lvl:",(one.auto_high_level_adjustment)?one.auto_high_level_adjustment:0)}
          {one_element("Algo rm within sec:",(one.algo_remove_within_delta_seconds)?one.algo_remove_within_delta_seconds:0)}
          {one_element("monitored:",one.monitored)}
          {one_element("message total:",one.message_data_total)}
          {one_element("message KO:",one.message_data_ko)}
          {one_element("KO ratio(%):",(one.message_data_total)&&(one.message_data_ko)&&Math.ceil((one.message_data_ko*100.0)/one.message_data_total))}
          */}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="panel-installation-flex">
      {transmitters && transmitters.map((one) => one_transmitter(one))}
      <ReactTooltip type="light" multiline={true} place="right" />
    </div>
  );
};

export default StationCardDiagnostics;
